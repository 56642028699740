/*jslint bitwise: true*/

/*exported GCurItem, GOrderInfo, fOrderID, fIDRetailer, fIDRetailerOrder, fIDWholesaler, fIDWholesalerOrder, fCOVERT, fGILET, fJACKET, fVEST, fTROUSERS, fSHIRT, fLADIESSKIRT, fPLUS234S, clothLookUpKeyUp, clothLookFFSwitchChange, clothLookSwitchChange, lookupFullClothString, CLOTHGUIDchange, showHelpPopup, showVideoPopup, setOrderTab,GOrderInfo, hideit, showit, showMessage, showWarning, showError, upperCase, lowerCase, cNone, cSlim, cVerySlim, cComfortable, cDB, cSB, cBusinessCasual, cCountryWear, cTuxedo, cFormalWear, cHighlandWear, cLadiesWear, cOuterWear, cSmoking, cTAILsm, cJKTsm, cLJKTFFsm, cLJKTNORMsm, cOCOATsm, cCOVERTsm, cHIGHtrs, cLOWtrs, cTRSsm, cLSHIRTsm, cSHIRTsm, cLVESTsm, cVESTsm, cSKIRTsm, cPLUSsm, getDownSize, shoulderLength, curSizeChart, jacketStyle, populateOrderValues, showOrders, fOrderFlags, getUserFloatValue, getUserIntegerValue, blockPattern, cClassic, cWestEnd, cPrinceCharlie, cCovert, cWECoat, cOCoat, cPeaCoat, cTrendCoat, cFieldCoat, cRaglan, cDahlia, cPoppy, cJasmine, cRosa, cIvy, cElk, cMorningTails, cEveningTails, calcHalfWaist,calcBodyWaist,bodyFitting, sleeveLength, lengthStyle, buttonClosing, standardPointToPoint, checkSubmitButtons, populateStyleValues, cCustomerProfile, fJKTII, fTRII, fVII, fSKIRTII, fPLUS234SII, fGILETII, fSHIRTII, cTableJacket, cTableTrousers,  cTableJacketII, cTableTrousersII, cTableVestII, cTableSKIRTII, cTablePLUS234SII, cTableGILETII, cTableSHIRTII, cTableDRESSII, cJacket, cTrousers, cJacketII, cTrousersII, cVestII, cSkirtII, cPlus234SII, cGiletII, cShirtII, cDressII, cloneOrder, fIDCollectionOrder, doJWRecalc, recalcCTOC, toFixed, hasClothCommentsWarning, checkRef, roundUp, loadFromTrouser, showBasket, fDebug1, fDebug2, fDebug3, copyCustomCloths, translator, dict, show3dPreview, populateOrderValues3D, fViewOnly,fDRESSII,relinkEvents,cClothMsg,changeLevel,orderComment */

/*global bootbox, fIsLGFG, orderPages, escape, fRoles, getToken, console, toFixed, translator, showError, showSuccess, showMessage, fDebug,$,toastr, showLoadingPage, hideLoading, checkPageChange,previewOrder3D,mobileNavClick,cBrand,clearLogonDetails,previousPage,fActiveGrid*/
/*jshint esversion: 6 */
//JSONstring,
//var fDebug               = false;
var fDebug1 = false;
var fDebug2 = false;
var fDebug3 = false;
var fViewOnly = true;

var fOrderID = '';
var fIDRetailer = 0;
var fIDRetailerOrder = 0;
var fIDEmployee = 0;
var fIDEmployeeOrder = 0;
var fIDWholesaler = 0;
var fIDWholesalerOrder = 0;
var fIDCollectionOrder = 0;
var fCOVERT = false;
var fDRESSII = false;
var fGILET = false;
var fJACKET = false;
var fLADIESSKIRT = false;
var fPLUS234S = false;
var fVEST = false;
var fTROUSERS = false;
var fSHIRT = false;
var fJKTII = false;
var fTRII = false;
var fVII = false;
var fSKIRTII = false;
var fPLUS234SII = false;
var fGILETII = false;
var fSHIRTII = false;
var fOrderFlags = 0;

var stylevalues = {};

const cCustomClothGUID = '7AD0E2D7-807C-49EC-9091-E316336AA15F';

const cTableJacketII = 'LAM_JKTII';
const cTableTrousersII = 'LAM_TRII';
const cTableVestII = 'LAM_VII';
const cTableSKIRTII = 'LAM_SKIRTII';
const cTablePLUS234SII = 'LAM_PLUS234SII';
const cTableGILETII = 'LAM_GILETII';
const cTableSHIRTII = 'LAM_SHIRTII';
const cTableDRESSII = 'LAM_DRESSII';

const cJacket = 'Jacket';
const cTrousers = 'Trousers';
const cJacketII = 'Jacket' + ' II';
const cTrousersII = 'Trouser' + ' II';
const cVestII = 'Vest' + ' II';
const cSkirtII = 'Skirt' + ' II';
const cPlus234SII = 'Plus 2,3,4\'s' + ' II';
const cGiletII = 'Gilet' + ' II';
const cShirtII = 'Shirt' + ' II';
const cDressII = 'Dress' + ' II';

const cCustomerProfile = '{61B211B6-2D95-4360-8D40-C23513F24643}';

var fJWTYPE_OLD = '';
var fJWDIRECTION = 0;

//var cClothMsgOld = 'Additional Cloth / Lining information <br><small>NOTE : Please clearly state "CMT" (Ordered on your account) or "Customer Supplied" for NON FF orders if the cloth number cannot be found. Ensure accurate measurements for usable width, check, stripe and cut one way cloth information is given for Customer Supplied cloth. Failure to do so may result in the cloth having to be returned.</small>';

var cClothMsg = 'Additional Cloth / Lining information<br><small> NOTE : Please clearly state "CMT" (Ordered on your account) or "Customer Supplied" (You will send the cloth to Lambton). ** If you do not specify orders will be treated as CMT **. Ensure accurate measurements for usable width, check, stripe and cut one way cloth information is given for Customer Supplied cloth. Failure to do so may result in a surcharge for re planning the markers when possible, or the cloth having to be returned.</small>';


/*
function isInt(n) {
    return Number(n) === n && n % 1 === 0;
}

function isFloat(n) {
    return Number(n) === n && n % 1 !== 0;
}
*/
class ObserverObj {
    constructor(pElem, pTrigger) {
        this.elem = pElem;
        this.hasUpdated = false;
        if (pTrigger) {
            this.trigger = pTrigger;
        } else {
            this.trigger = 'visiblecheck';
        }
    }
}

class Observer {
    constructor(elem) {
        this.element = $(elem);
        this.observers = [];
        this.hasUpdated = false;
        this.id = 0;
        this.updateFunc = function () {
            console.log('updateFunc for ' + this.id + ' must be set');
        };

        this.element.on('change', function () {
            var obs = $(this).data('nt.observer');
            var obsList = obs.getObservers();
            for (var i = 0, len = obsList.length; i < len; i++) {
                if (obsList[i].hasUpdated) {
                    obsList[i].setHasUpdated(false);
                }
            }
            try {
                obs.update();
                obs.setHasUpdated(false);
            } catch (e) {
                console.error(this.id);
                console.error(e);
            }
        });

    }
    getObservers() {
        return this.observers;
    }
    setId(id) {
        this.id = id;
    }
    setHasUpdated(val) {
        this.hasUpdated = val;
    }
    addObserver(ob, triggerName) {
        try {
            var lObj = new ObserverObj(ob, triggerName);
            this.observers.push(lObj);
        } catch (e) {
            console.error(e);
        }
    }
    removeObserver(ob) {
        try {
            for (var i = 0, len = this.observers.length; i < len; i++) {
                if (this.observers[i] === ob) {
                    this.observers.splice(i, 1);
                    return true;
                }
            }
            return false;
        } catch (e) {
            console.error(e);
        }
    }
    notifyObservers() {
        for (var i = 0, len = this.observers.length; i < len; i++) {
            try {
                if (fDebug) {
                    console.log('Trigger: ' + $(this.observers[i].elem).attr('id'));
                    console.log('notifyObservers:(' + this.observers[i].trigger + ')');
                    console.log('hasUpdated:(' + this.observers[i].hasUpdated + ')');
                }
                if (this.observers[i].hasUpdated === false) {
                    $(this.observers[i].elem).trigger(this.observers[i].trigger);
                }
            } catch (e) {
                console.error(e);
            }
        }
    }
    update() {
        this.hasUpdated = true;
        this.notifyObservers();
    }
}

Observer.prototype.hasUpdated = function () {
    return this.hasUpdated;
};

//*** Convenience Functions ***//

function createObserver($) {
    return $.each(function () {
        var $this = $(this);
        var data = $this.data('nt.observer');
        if (!data) {
            $this.data('nt.observer', (data = new Observer($)));
        }
    });
}

$.fn.observer = createObserver($);
$.fn.observer.Constructor = Observer;

$.fn.setId = function (id) {
    var obs = $(this).data('nt.observer');
    if (!obs) {
        $(this).data('nt.observer', (obs = new Observer(this)));
    }
    obs.setId(id);
};

$.fn.addObserver = function (ob2, triggerName) {
    var obs = $(this).data('nt.observer');
    if (!obs) {
        $(this).data('nt.observer', (obs = new Observer(this)));
    }
    obs.addObserver(ob2, triggerName);
};

$.fn.removeObserver = function (ob2) {
    var obs = $(this).data('nt.observer');
    if (!obs) {
        $(this).data('nt.observer', (obs = new Observer(this)));
    }
    return obs.removeObserver(ob2);
};

$.fn.update = function () {
    var obs = $(this).data('nt.observer');
    if (!obs) {
        $(this).data('nt.observer', (obs = new Observer(this)));
    }
    try {
        obs.update();
    } catch (e) {
        console.error(e);
    }
};

$.fn.setUpdateFunc = function (func) {
    var obs = $(this).data('nt.observer');
    if (!obs) {
        $(this).data('nt.observer', (obs = new Observer(this)));
    }
    obs.updateFunc = func;
};

$.fn.setHasUpdated = function (val) {
    var obs = $(this).data('nt.observer');
    if (!obs) {
        $(this).data('nt.observer', (obs = new Observer(this)));
    }
    obs.setHasUpdated(val);
};

$.fn.hasUpdated = function () {
    var obs = $(this).data('nt.observer');
    if (!obs) {
        $(this).data('nt.observer', (obs = new Observer(this)));
    }
    return obs.hasUpdated;
};

// ====================================================================================================
// Order Utils
// ====================================================================================================

class ErrorObj {
    constructor(pElem, pTab, pGarment, pMessage) {
        this.elem = pElem;
        this.Tab = '\'' + pTab + '\'';
        this.Garment = pGarment;
        this.Message = pMessage;
    }
}

class ErrorList {
    get div() {
        return this.adiv;
    }

    set div(pDiv) {
        this.adiv = pDiv;
    }
    constructor(pDiv) {
        this.errors = [];
        this.adiv = pDiv;
        this.hasUpdated = false;
        this.count = 0;
    }
    find(pObj) {
        var found = false;
        if (fDebug3) {
            console.log('ErrorList.prototype.find');
        }
        $.each(this.errors, function (i, val) {
            if ((val.elem === pObj.elem) &&
                (val.Tab === pObj.Tab) &&
                (val.Garment === pObj.Garment) &&
                (val.Message = pObj.Message)) {
                if (fDebug3) {
                    console.log('elem : ' + val.elem);
                    console.log('Message : ' + val.Message);
                    console.log('Tab : ' + val.Tab);
                    console.log('Garment : ' + val.Garment);
                }
                found = true;
            }
        });
        return found;
    }
    sort() {
        function sortByName(a, b) {
            try {
                if ((typeof a.Garment !== 'undefined') && (typeof b.Garment !== 'undefined')) {
                    var aName = a.Garment.toLowerCase();
                    var bName = b.Garment.toLowerCase();
                    return ((aName < bName) ? -1 : ((aName > bName) ? 1 : 0));
                } else {
                    return -1;
                }
            } catch (e) {
                console.error('ErrorList.prototype.sort.sortByName');
                console.error(e);
            }
        }
        this.errors.sort(sortByName);
    }
    addError(pObj) {
        try {
            if (fDebug3) {
                console.debug('ErrorList.prototype.addError');
                console.debug(pObj);
            }
            this.hasUpdated = false;
            if (!this.find(pObj)) {
                this.errors.push(pObj);
            }
            this.count = this.errors.length;
            if (fDebug3) {
                console.debug('ErrorList Count : ' + this.count);
            }
            this.update();
        } catch (e) {
            console.error(e);
        }
    }
    delError(pObj) {
        try {
            if (fDebug3) {
                console.debug('ErrorList.prototype.delError');
                console.debug(pObj);
            }
            this.hasUpdated = false;
            this.errors = $.grep(this.errors, function (value) {
                return value.elem !== pObj.elem;
            });
            this.count = this.errors.length;
            if (fDebug3) {
                console.debug('ErrorList Count : ' + this.count);
            }
            this.update();
        } catch (e) {
            console.error(e);
        }
    }
    update() {
        try {
            if (fDebug3) {
                console.debug('ErrorList.prototype.update');
            }
            var lHTML = '';
            if (!this.hasUpdated) {
                this.hasUpdated = true;
                this.sort();
                if (this.count > 0) {
                    if (fDebug3) {
                        console.debug(this);
                        console.debug('ErrorList: Showing Error Box');
                    }
                    $.each(this.errors, function (i, val) {
                        if (fDebug3) {
                            console.debug('Update:');
                            console.debug(val);
                        }
                        if (typeof val !== 'undefined') {
                            lHTML = lHTML + '<li>' + '[<a href="#" onclick="setOrderTab(jQuery,' + val.Tab + ');">' + translator.get('Goto') + ' ' + val.Garment + '</a>] ' + val.Message + '</li>';
                        }
                    });
                    this.adiv.html(
                        '<div class="row">' +
                        '<div class="col-xs-12 col-sm-10 col-md-10 col-lg-10"><ul class="list-unstyled">' +
                        lHTML +
                        '</ul></div>' +
                        '<div class="col-xs-12 col-sm-2 col-md-2 col-lg-2">' +
                        '<button class="btn btn-danger btn-sm pull-right recheck" id="btnrecheck" name="btnrecheck">' + translator.get('Recheck') + '</button>' +
                        '</div>' +
                        '</div>').show();

                    $('.recheck').unbind('click').on('click', function () {
                        $('.verinput').change();
                    });

                    if (fDebug3) {
                        console.debug(this.adiv.attr('id'));
                        console.debug(this.adiv.html());
                        console.debug(lHTML);
                    }
                } else {
                    if (fDebug3) {
                        console.debug(this);
                        console.debug('ErrorList: Hiding Error Box');
                    }
                    this.adiv.html('').hide();
                }
            } else {
                if (fDebug3) {
                    console.debug('ErrorList: No Update Needed');
                }
            }
        } catch (e) {
            console.error(e);
        }
    }
    clear() {
        this.errors = [];
        this.count = 0;
    }
}

class WarnObj {
    constructor(pElem, pTab, pGarment, pMessage) {
        this.elem = pElem;
        this.Tab = '\'' + pTab + '\'';
        this.Garment = pGarment;
        this.Message = pMessage;
    }
}

class WarnList {
    get div() {
        return this.adiv;
    }
    set div(pDiv) {
        this.adiv = pDiv;
    }
    constructor(pDiv) {
        this.warns = [];
        this.adiv = pDiv;
        this.hasUpdated = false;
        this.count = 0;
    }
    find(pObj) {
        var found = false;
        try {
            if (fDebug3) {
                console.debug('WarnList.prototype.find');
            }
            $.each(this.warns, function (i, val) {
                if ((val.elem === pObj.elem) &&
                    (val.Tab === pObj.Tab) &&
                    (val.Garment === pObj.Garment) &&
                    (val.Message = pObj.Message)) {
                    if (fDebug3) {
                        console.debug('elem : ' + val.elem);
                        console.debug('Message : ' + val.Message);
                        console.debug('Tab : ' + val.Tab);
                        console.debug('Garment : ' + val.Garment);
                    }
                    found = true;
                }
            });
        } catch (e) {
            console.error(e);
        }
        return found;
    }
    sort() {
        function sortByName(a, b) {
            try {
                if ((typeof a.Garment !== 'undefined') && (typeof b.Garment !== 'undefined')) {
                    var aName = a.Garment.toLowerCase();
                    var bName = b.Garment.toLowerCase();
                    return ((aName < bName) ? -1 : ((aName > bName) ? 1 : 0));
                } else {
                    return -1;
                }
            } catch (e) {
                console.error(e);
            }
        }
        try {
            this.warns.sort(sortByName);
        } catch (e) {
            console.error(e);
        }
    }
    addWarn(pObj) {
        try {
            if (fDebug3) {
                console.debug('WarnList: addWarn');
                console.debug(pObj);
            }
            this.hasUpdated = false;
            if (!this.find(pObj)) {
                this.warns.push(pObj);
            }
            this.count = this.warns.length;
            if (fDebug3) {
                console.debug('WarnList Count : ' + this.count);
            }
            this.update();
        } catch (e) {
            console.error(e);
        }
    }
    delWarn(pObj) {
        try {
            if (fDebug3) {
                console.debug('WarnList: delError');
                console.debug(pObj);
            }
            this.hasUpdated = false;
            this.warns = $.grep(this.warns, function (value) {
                return value.elem !== pObj.elem;
            });
            this.count = this.warns.length;
            this.update();
        } catch (e) {
            console.error(e);
        }
    }
    update() {
        try {
            var lHTML = '';
            if (!this.hasUpdated) {
                this.hasUpdated = true;
                this.sort();
                if (this.count > 0) {
                    if (fDebug3) {
                        console.debug('WarnList: Showing Warning Box');
                    }
                    $.each(this.warns, function (i, val) {
                        if (typeof val !== 'undefined') {
                            lHTML = lHTML + '<li>' + '[<a href="#" onclick="setOrderTab(jQuery,' + val.Tab + ');">' + translator.get('Goto') + ' ' + val.Garment + '</a>] ' + val.Message + '</li>';
                        }
                    });
                    this.adiv.html(
                        '<div class="row">' +
                        '<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12"><ul class="list-unstyled">' +
                        lHTML +
                        '</ul></div>' +
                        '</div>').show();
                } else {
                    if (fDebug3) {
                        console.debug('WarnList: Hiding Warning Box');
                    }
                    this.adiv.html('').hide();
                }
            } else {
                if (fDebug3) {
                    console.debug('WarnList: No Update Needed');
                }
            }
        } catch (e) {
            console.error(e);
        }
    }
    clear() {
        this.warns = [];
        this.count = 0;
    }
}

$.fn.hideit = function (clear, pDefault) {
    try {
        if (typeof this !== 'undefined') {
            var div = $('#div' + this.attr('id'));
            div.removeClass("show");
            div.addClass("hidden");
            $(this).setFailure(false);
            if (typeof clear !== "undefined" && clear) {
                $(this).val('');
                if ($(this).is("textarea")) {
                    $(this).text('');
                }
                $(this).attr('data-cguid', '');
                $(this).updateField();
                //Causes recursive loops if this directly calls an OnChange
                //$(this).change();
            }
            if (!clear && pDefault || (typeof pDefault === 'boolean')) {
                if (typeof pDefault === 'boolean') {
                    if (this.attr('data-val') === '') {
                        if (pDefault) {
                            $('#' + this.attr('id')).bootstrapToggle('on');
                        } else {
                            $('#' + this.attr('id')).bootstrapToggle('off');
                        }
                    }
                } else {
                    this.val(pDefault);
                }
            }
        }
    } catch (e) {
        console.error(e);
    }
};

$.fn.showit = function (pDefault) {
    var me = this;
    var id = this.attr('id');
    try {
        if (id !== undefined) {
            var div = $('#div' + id);
            div.removeClass("hidden");
            div.addClass("show");
            if (pDefault || (typeof pDefault === 'boolean')) {
                try {
                    if (typeof pDefault === 'boolean') {
                        if (this.attr('data-val') === '') {
                            if (pDefault) {
                                $('#' + id).bootstrapToggle('on');
                            } else {
                                $('#' + id).bootstrapToggle('off');
                            }
                        }
                    } else if ( /*(!this.isVisible()) ||*/
                        (!this.val()) ||
                        (this.val() === '') ||
                        (this.val().toLowerCase() === 'please select') ||
                        (this.val().toLowerCase() === '<please select>') ||
                        (this.val() === null) ||
                        (this.find(':selected').prop('disabled') === true)) {
                        $('#' + id + ' option[value="' + pDefault + '"]').prop("selected", true);
                        this.val(pDefault);
                    } else if (fDebug) {
                        console.debug('Nothing To Set:');
                        console.debug(!this.val());
                        console.debug(this.val() === '');
                        console.debug(this.val().toLowerCase() === 'please select');
                        console.debug(this.val().toLowerCase() === '<please select>');
                        console.debug(this.val() === null);
                    }
                } catch (e) {
                    console.error(e);
                }
            } else if (!this.is("textarea") && !this.is("div")) {
                console.warn('No default specified for ' + id);
            }

            // try {
            //     this.update();
            // } catch (e) {
            //     console.error('Error: ' + id + ' fn.update');
            //     console.error(me);
            //     console.error(e);
            // }

            try {
                this.trigger('verifyme');
            } catch (e) {
                console.error('Error: ' + id + ' fn.showit / verifyme');
                console.error(me);
                console.error(e);
            }
            try {
                this.trigger('renderscene');
            } catch (e) {
                console.error('Error: ' + id + ' fn.showit / renderscene');
                console.error(me);
                console.error(e);
            }
        }
    } catch (e) {
        console.error('Error: ' + id + ' fn.showit');
        console.error(me);
        console.error(e);
    }
};


$.fn.verifySelf = function () {
    try {
        if (this.hasClass("verinput")) {
            if ((this.attr('disabled') === 'disabled') ||
                (this.val() !== '') ||
                (this.prop('disabled') === true)) {
                this.removeClass("verifyfailure");
                $("#div" + this.attr('id')).removeClass("danger");
            } else {
                this.addClass("verifyfailure");
                $("#div" + this.attr('id')).addClass("danger");
            }
        }
    } catch (e) {
        console.error(e);
    }
};

$.fn.setFailure = function (pVal, pMsg, pTab, pGarment) {
    if (fDebug) {
        console.debug(pVal);
        console.debug(pMsg);
        console.debug(pTab);
        console.debug(pGarment);
    }
    try {
        if (typeof ErrorObjectList !== 'undefined') {
            var lErr = new ErrorObj(this.attr('id'), pTab, pGarment, pMsg);
            if (pVal) {
                this.removeAttr('title');
                this.attr('data-original-title', pMsg);
                this.attr('data-selector', 'true');
                this.attr('data-toggle', 'tooltip');
                this.attr('data-placement', 'bottom');
                if (typeof this.tooltip === 'function') {
                    this.tooltip({
                        placement: 'bottom',
                        trigger: 'hover focus'
                    });
                }
                this.addClass("verifyfailure");
                $("#div" + this.attr('id')).addClass("danger");
                ErrorObjectList.addError(lErr);
            } else {
                this.removeAttr('data-original-title');
                this.removeAttr('title');
                this.removeAttr('data-selector');
                this.removeAttr('data-toggle');
                this.removeAttr('data-placement');
                if (typeof this.tooltip === 'function') {
                    this.tooltip('destroy');
                }
                this.removeClass("verifyfailure");
                $("#div" + this.attr('id')).removeClass("danger");
                ErrorObjectList.delError(lErr);
            }
        } else {
            console.error('ErrorObjectList undefined');
        }
    } catch (e) {
        console.error(e);
    }
};

$.fn.setWarning = function (pVal, pMsg, pTab, pGarment) {
    if (fDebug) {
        console.debug(pVal);
        console.debug(pMsg);
        console.debug(pTab);
        console.debug(pGarment);
    }
    try {
        if (typeof WarnObjectList !== 'undefined') {
            var lWarn = new WarnObj(this.attr('id'), pTab, pGarment, pMsg);
            if (pVal) {
                this.removeAttr('title');
                this.attr('data-original-title', pMsg);
                this.attr('data-selector', 'true');
                this.attr('data-toggle', 'tooltip');
                this.attr('data-placement', 'bottom');
                if (typeof this.tooltip === 'function') {
                    this.tooltip({
                        placement: 'bottom',
                        trigger: 'hover focus'
                    });
                }
                this.addClass("verifywarning");
                $("#div" + this.attr('id')).addClass("warning");
                WarnObjectList.addWarn(lWarn);
            } else {
                this.removeAttr('data-original-title');
                this.removeAttr('title');
                this.removeAttr('data-selector');
                this.removeAttr('data-toggle');
                this.removeAttr('data-placement');
                if (typeof this.tooltip === 'function') {
                    this.tooltip('destroy');
                }
                this.removeClass("verifywarning");
                $("#div" + this.attr('id')).removeClass("warning");
                WarnObjectList.delWarn(lWarn);
            }
        } else {
            console.error('WarnObjectList undefined');
        }
    } catch (e) {
        console.error(e);
    }
};

$.fn.isVisible = function () {
    try {
        var div = $('#div' + this.attr('id'));
        if (div.hasClass('hidden')) {
            return false;
        } else {
            return true;
        }
    } catch (e) {
        console.log("Exception:" + this.attr('id'));
        console.error(e);
    }
};

$.fn.clearField = function () {
    try {
        $(this).val('');
        $(this).change();
    } catch (e) {
        console.error(e);
    }
};

$.fn.getLookupValues = function (pKey, pFunc) {
    try {
        $.ajax({
            type: 'post',
            async: false,
            dataType: 'json',
            url: '/scripts-pas/getLookupValues.fsscript?key=' + pKey,
            data: {
                "key": pKey
            },
            success: function (r) {
                if (r.error !== true) {
                    if (typeof pFunc === 'function') {
                        pFunc(r[0].values);
                    }
                } else {
                    if (fDebug) {
                        console.debug(r);
                    }
                }
            },
            error: function (e) {
                if (e.status === 403) {
                    clearLogonDetails();
                    window.location = '/';
                }
                console.error('getLookupValues Failure');
                console.error(e);
            }
        });
    } catch (e) {
        console.error(e);
    }
};

$.fn.getClothName = function (pField, pFunc) {
    var cGUID = $('#' + pField).attr('data-cguid');
    try {
        $.ajax({
            type: 'post',
            async: false,
            dataType: 'json',
            url: '/scripts-pas/clothname.fsscript?c=' + cGUID,
            data: {
                "c": cGUID
            },
            success: function (r) {
                if (r.error !== true) {
                    if (typeof pFunc === 'function') {
                        pFunc(r[0].values);
                    }
                } else {
                    if (fDebug) {
                        console.debug(r);
                    }
                }
            },
            error: function (e) {
                console.error('getClothName Failure');
                console.error(e);
            }
        });
    } catch (e) {
        console.error(e);
    }
};

$.fn.fuzzySetOld = function (pVal /*, pDec*/) {
    try {
        var lToSet;
        var id = $(this).attr('id');
        var lSet = parseFloat(pVal);
        var lLast = 0;
        var lInc = 0;
        $('#' + id + ' option').each(function () {
            lInc = (parseFloat($(this).val()) - lLast) / 2;
            lLast = $(this).val();
        });
        $('#' + id + ' option').each(function () {
            var lVal = parseFloat($(this).val());
            var lCur = $(this).val();
            if (lSet === lVal) {
                lToSet = lCur;
            } else if (lSet > lVal - lInc) { //8.24 8.25 - (0.125/2) 8.1875
                lToSet = lCur;
            }
        });
        // if(fDebug){
        // 	if(pDec){
        // 		console.log('Found:' + lSet + '/' + toFixed(lToSet, pDec));
        // 	}else{
        // 		console.log('Found:' + lSet + '/' + lToSet);
        // 	}
        // }
        $('#' + id).val(lToSet);
        $('#' + id).selectOptions(lToSet, true);
    } catch (e) {
        console.error(e);
    }
};

$.fn.fuzzySet = function (pVal) {
    try {
        var lToSet;
        var id = $(this).attr('id');
        var lSet = parseFloat(pVal);
        var lLast = 0;
        var lInc = 0;
        $(this).find('option').each(function () {
            lInc = (parseFloat($(this).val()) - lLast) / 2;
            lLast = $(this).val();
        });
        $(this).find('option').each(function () {
            var lVal = parseFloat($(this).val());
            var lCur = $(this).val();
            if (lSet === lVal) {
                lToSet = lCur;
            } else if (lSet > lVal - lInc) { //8.24 8.25 - (0.125/2) 8.1875
                lToSet = lCur;
            }
        });
        if (fDebug) {
            console.debug('Found:' + lSet + '/' + lToSet);
        }
        $('#' + id).val(lToSet);
        $('#' + id).selectOptions(lToSet, true);
    } catch (e) {
        console.error(e);
    }
};

$.fn.translateJS = function () {
    try {
        $.ajax({
            type: 'post',
            async: false,
            dataType: 'json',
            url: '/scripts-pas/translate.fsscript?string=' + $(this).val(),
            data: {
                "string": $(this).val()
            },
            success: function (r) {
                if (!r.error) {
                    $(this).val(r.translation);
                } else {
                    console.log('translate Failure');
                    console.log(r);
                }
            },
            error: function (e) {
                console.log('translate Failure');
                console.log(e);
            }
        });
    } catch (e) {
        console.error(e);
    }
};

$.fn.translateThis = function (pString, pFunc) {
    try {
        if (fDebug) {
            console.debug('translate ' + pString);
        }
        $.ajax({
            type: 'post',
            async: false,
            dataType: 'json',
            url: '/scripts-pas/translate.fsscript?string=' + pString,
            data: {
                "string": pString
            },
            success: function (r) {
                if (!r.error) {
                    if (typeof pFunc === 'function') {
                        pFunc(r.translation);
                    }
                } else {
                    if (fDebug) {
                        console.debug(r);
                    }
                }
                return r.error;
            },
            error: function (e) {
                console.log('translateThis Failure');
                console.log(e);
            }
        });
    } catch (e) {
        console.error(e);
    }
};

$.fn.advclothsearch = function (options) {
    var ls = {
        url: "/scripts-pas/clothslookup.fsscript",
        form_anti_bot: "ajaxlivesearch_guard",
        cache: false,
        type_delay: 350,
        time_out: 20000,
        max_input: 40,
        min_chars_to_search: 1,
        cloth_count: 0,
        cloth_list: "",
        retry_count: 0
    };

    ls = $.extend(ls, options);

    function search(search_object, options) {
        if ($.trim(search_object.value).length && $.trim(search_object.value).length >= options.min_chars_to_search) {
            var lQuery = search_object.value;

            if (search_object.clothTimer) {
                clearTimeout(search_object.clothTimer);
            }

            search_object.clothTimer = setTimeout(function () {
                var lff = 'false';
                var lfactory = 'false';
                var lHouse = 'false';
                var lMerchantType = $("#MTYPE").val();

                if ($('#chkHouseBunch').prop('checked') === true ||
                    $('#chkMainClothIsFF').prop('checked') === true) {
                    lff = 'true';
                } else {
                    lff = 'false';
                }
                if ($('#chkHouseBunch').prop('checked') === true) {
                    lHouse = 'true';
                } else {
                    lHouse = 'false';
                }
                if ($('#chkMainClothFactory').prop('checked') === true) {
                    lfactory = 'true';
                } else {
                    lfactory = 'false';
                }

                if (lQuery !== '') {
                    $("#divCLOTHSTR").removeClass('show').addClass('hidden');
                    $("#divBALANCESTR").removeClass('show').addClass('hidden');
                    $("#divSPECIALSTR").removeClass('show').addClass('hidden');
                    $("#divSTOCKQTY").removeClass('show').addClass('hidden');
                    $("#clothcount").html('Searching <i class="fa fa-spinner fa-pulse fa-fw"></i>');
                    $("#CLOTHGUID").attr("disabled", "disabled");
                    $("#CLOTHGUID").html('<option value="" selected>' + translator.get('Pick Cloth') + '</option>');
                    $("#IMAGESTR").html("");
                    var toPostData = {
                        "query": lQuery,
                        "ctype": lMerchantType,
                        "ff": lff,
                        "house": lHouse,
                        "factory": lfactory,
                        "IDRetailer": fIDRetailerOrder,
                        "IDCollection": fIDCollectionOrder
                    };
                    var lLookup = "?query=" + lQuery + "&ctype=" + lMerchantType + "&ff=" + lff + "&house=" + lHouse + "&factory=" + lfactory + "&IDRetailer=" + fIDRetailerOrder + "&IDCollection=" + fIDCollectionOrder;
                    if (fDebug) {
                        console.debug(lLookup);
                    }
                    $.ajax({
                        type: "POST",
                        url: options.url + lLookup,
                        data: toPostData,
                        dataType: "json",
                        cache: options.cache,
                        timeout: options.time_out,
                        success: function (data) {
                            if (fDebug) {
                                console.debug('Success');
                                console.debug(data);
                            }
                        },
                        error: function (jqXHR, textStatus) {
                            console.error(jqXHR);
                            console.log(options.retry_count);
                            if (textStatus === "timeout") {
                                if (options.retry_count < 10) {
                                    options.retry_count = options.retry_count + 1;
                                    search(search_object, options);
                                } else {
                                    options.retry_count = 0;
                                    $("#CLOTHGUID").html('<option value="" selected>' + translator.get('Timeout') + '</option>');
                                    $("#CLOTHGUID").attr("disabled", "disabled");
                                    $("#clothcount").html('Cloth Lookup Timeout, please retry');
                                }
                            } else {
                                console.error(textStatus);
                                $("#CLOTHGUID").html('<option value="" selected>' + translator.get('None Found') + ' (JSON Error)</option>');
                                $("#CLOTHGUID").attr("disabled", "disabled");
                                $("#clothcount").html('Error Finding Cloths');
                            }
                        },
                        complete: function (data) {
                            if (fDebug) {
                                console.debug('Complete');
                                console.debug(data);
                            }
                            if ($("#clothlookup").val() === lQuery && lQuery !== '') {
                                if (data.statusText === "OK") {
                                    options.cloth_list = data.responseJSON;
                                    options.cloth_count = options.cloth_list.length;
                                    var lList = '<option value="" selected>' + translator.get('Pick Cloth') + '</option>';
                                    if (options.cloth_count > 0 && options.cloth_list[0].CLOTHGUID !== undefined) {
                                        for (var element in options.cloth_list) {
                                            if (options.cloth_list[element].CLOTHGUID !== undefined) {
                                                lList = lList + '<option value="' + options.cloth_list[element].CLOTHGUID + '">' + options.cloth_list[element].CLOTHSTR + '</option>';
                                            }
                                        }
                                        if ($("#clothlookup").val() === lQuery) {
                                            $("#CLOTHGUID").removeAttr('disabled');
                                            if (options.cloth_count >= 200) {
                                                $("#clothcount").html(translator.get('Matching Cloths (more than 200)'));
                                            } else {
                                                $("#clothcount").html(translator.get('Matching Cloths') + ' (' + options.cloth_count + ')');
                                            }
                                        } else {
                                            $("#clothcount").html('Searching <i class="fa fa-spinner fa-pulse fa-fw"></i>');
                                            $("#CLOTHGUID").attr("disabled", "disabled");
                                            $("#CLOTHGUID").html('<option value="" selected>' + translator.get('Pick Cloth') + '</option>');
                                        }
                                    } else {
                                        $("#clothcount").html(translator.get('Matching Cloths (0)'));
                                        lList = '<option value="" selected>' + translator.get('None Found') + '</option>';
                                    }
                                    $("#CLOTHGUID").html(lList);
                                }
                            } else {
                                $("#clothcount").html('Searching <i class="fa fa-spinner fa-pulse fa-fw"></i>');
                                $("#CLOTHGUID").html('<option value="" selected>' + translator.get('Pick Cloth') + '</option>');
                                $("#CLOTHGUID").attr("disabled", "disabled");
                            }
                        }
                    });
                } else {
                    $("#clothcount").html(translator.get('Matching Cloths'));
                    $("#CLOTHGUID").html('<option value="" selected>' + translator.get('Pick Cloth') + '</option>');
                    $("#CLOTHGUID").attr("disabled", "disabled");
                    $("#divCLOTHSTR").removeClass('show').addClass('hidden');
                    $("#divBALANCESTR").removeClass('show').addClass('hidden');
                    $("#divSPECIALSTR").removeClass('show').addClass('hidden');
                }
            }, options.type_delay);
        } else {
            $("#clothcount").html(translator.get('Matching Cloths'));
            $("#CLOTHGUID").html('<option value="" selected>' + translator.get('Pick Cloth') + '</option>');
            $("#CLOTHGUID").attr("disabled", "disabled");
            $("#divCLOTHSTR").removeClass('show').addClass('hidden');
            $("#divBALANCESTR").removeClass('show').addClass('hidden');
            $("#divSPECIALSTR").removeClass('show').addClass('hidden');
        }
    }

    $("#clothlookup").on("keypress", function (event) {
        var keycode = event.keyCode || event.which;
        if (fDebug) {
            console.debug(keycode);
        }
        if (keycode === 13) {
            search(this, ls);
            return false;
        }
    });
};

$.fn.checkClonedPatternComment = function () {
    var currComment = $(this).val();
    var cPatternWarning = '*** CHECK ORDER *** THIS PATTERN COMMENT HAS BEEN CLONED ***';
    var alreadyAsked = $(this).data('ASKED') === 'TRUE';
    if (currComment.indexOf(cPatternWarning) >= 0 && !alreadyAsked) {
        bootbox.alert('<h1>WARNING</h1>The pattern comment has been cloned. Please inform Customer Services if this no longer applies to the new order');
        $(this).data('ASKED', 'TRUE');
        if (fDebug) {
            console.log($(this).attr('id') + ':Asking');
        }
    } else {
        if (fDebug) {
            console.log($(this).attr('id') + ':Already Asked');
        }
    }
};

function hideit(id) {
    var div = $('#div' + id);
    div.removeClass("show");
    div.addClass("hidden");
}

function showit(id) {
    var div = $('#div' + id);
    div.removeClass("hidden");
    div.addClass("show");
}

var sizeMatrixObj;

var sizeMatrixMap = {};

class NewSizeMatrix {
    constructor() {
        this.sizeMatrix = [];
        var _this = this;
        this.CacheSizeMatrix = function () {
            try {
                $.ajax({
                    type: 'post',
                    async: false,
                    dataType: 'json',
                    url: '/scripts-pas/SizeMatrixCache.fsscript',
                    success: function (r) {
                        if (r.error !== true) {
                            _this.sizeMatrix = r.rows;
                            var i = null;
                            var str = '';
                            for (i = 0; _this.sizeMatrix.length > i; i += 1) {
                                str = String(_this.sizeMatrix[i].SIZETABLE).toLowerCase() +
                                    String(_this.sizeMatrix[i].CHESTSEAT).toLowerCase();
                                sizeMatrixMap[str] = _this.sizeMatrix[i];
                            }
                        } else if (fDebug1) {
                            console.log(r);
                        }
                    },
                    error: function (e) {
                        console.warn('SizeMatrixCache Lookup Failure');
                        console.warn(e);
                    }
                });
            } catch (e) {
                console.error(e);
            }
        };
    }
}

class GCurItem {
    constructor(pObj, pCount, pIGUID, pGarment, pIDCollection) {
        this.OrderID = fOrderID;
        this.IDRetailer = fIDRetailer;
        this.IDRetailerOrder = fIDRetailerOrder;
        this.IDEmployee = fIDEmployee;
        this.IDEmployeeOrder = fIDEmployeeOrder;
        this.IDWholesaler = fIDWholesaler;
        this.IDWholesalerOrder = fIDWholesalerOrder;
        this.IGUID = pIGUID;
        this.Garment = pGarment;
        this.IDCollection = pIDCollection;
        this.IDCollectionOrder = pIDCollection;
        this.OrderFlags = parseInt(fOrderFlags);
        this.Prefix = '';
        this.element = pObj;

        try {
            if (sizeMatrixObj === undefined) {
                sizeMatrixObj = new NewSizeMatrix();
                sizeMatrixObj.CacheSizeMatrix();
            }
        } catch (e) {
            console.error(e);
        }

        try {
            if (parseInt(pCount) > 0) {
                this.Prefix = 'T' + pCount + '_';
            } else {
                this.Prefix = '';
            }

            this.GetObj = function (pName) {
                try {
                    var lName = this.Prefix + pName;
                    if (fDebug1) {
                        console.log(lName);
                        console.log(typeof $('#' + lName));
                    }
                    return $('#' + lName);
                } catch (e) {
                    console.error(e);
                }
            };

            this.EnableOption = function (pName, pOption) {
                try {
                    var lName = this.Prefix + pName;
                    if (fDebug1) {
                        console.log('Enable:' + lName + ':' + pOption);
                        console.log(typeof $('#' + lName));
                    }
                    $('#' + lName + ' option[value="' + pOption + '"]').prop("disabled", false);
                } catch (e) {
                    console.error(e);
                }
            };

            this.DisableOption = function (pName, pOption) {
                try {
                    var lName = this.Prefix + pName;
                    if (fDebug1) {
                        console.log('Disable:' + lName + ':' + pOption);
                        console.log(typeof $('#' + lName));
                    }
                    $('#' + lName + ' option[value="' + pOption + '"]').prop("disabled", true);
                } catch (e) {
                    console.error(e);
                }
            };

            this.HideField = function (pName, pClear) {
                try {
                    var lName = this.Prefix + pName;
                    if (fDebug1) {
                        console.log('HideField:' + lName);
                        console.log(typeof $('#' + lName));
                    }
                    $('#' + lName).hideit(pClear);
                } catch (e) {
                    console.error(e);
                }
            };

            this.ShowField = function (pName) {
                try {
                    var lName = this.Prefix + pName;
                    if (fDebug1) {
                        console.log('ShowField:' + lName);
                        console.log(typeof $('#' + lName));
                    }
                    $('#' + lName).showit();
                } catch (e) {
                    console.error(e);
                }
            };

            this.GField = function (pName) {
                try {
                    var lName = this.Prefix + pName;
                    if (fDebug1) {
                        console.log(lName);
                    }
                    if ($('#' + lName).attr('data-on') === 'Yes' ||
                        $('#' + lName).attr('data-toggle') === 'toggle') {
                        // if(fDebug1){
                        // 			      			console.log($("[name='" + lName + "']").bootstrapSwitch('state'));
                        //       				}
                        //					return $("[name='" + lName + "']").bootstrapSwitch('state');
                        return $('#' + lName).prop('checked') === true;
                    } else {
                        var lVal = $('#' + lName).val();
                        if (lVal) {
                            if (fDebug1) {
                                console.log(lVal);
                            }
                            return lVal;
                        } else {
                            return '';
                        }
                    }
                } catch (e) {
                    console.error(e);
                }
            };

            this.SField = function (pName, pValue) {
                try {
                    var hasChanged = false;
                    var lName = this.Prefix + pName;
                    var lObj = $('#' + lName);
                    //var hasChanged = $('#'+lName).val() !== pValue;
                    if (fDebug) {
                        console.log(lName + ':' + pValue + ':' + typeof pValue + ':' + lObj.val());
                    }
                    if (typeof pValue === "boolean") {
                        if (pValue !== lObj.prop('checked')) {
                            hasChanged = true;
                            if (pValue) {
                                lObj.bootstrapToggle('on');
                            } else {
                                lObj.bootstrapToggle('off');
                            }
                        }
                    } else if (typeof pValue === "string") {
                        if (pValue.toUpperCase() === 'TRUE' && lObj.prop('checked') !== true) {
                            lObj.bootstrapToggle('on');
                            hasChanged = true;
                        } else if (pValue.toUpperCase() === 'FALSE' && lObj.prop('checked') === true) {
                            lObj.bootstrapToggle('off');
                            hasChanged = true;
                        } else {
                            if (lObj.val() !== pValue) {
                                hasChanged = true;
                                if (pValue === '0') {
                                    lObj.val(lObj.attr('data-cur'));
                                } else {
                                    lObj.val(pValue);
                                }
                            }
                        }
                    } else if (typeof pValue === "number") {
                        if (lObj.val() !== pValue) {
                            $('option:selected', 'select[name="' + lName + '"]').removeAttr('selected');
                            $("#" + lName + " > option").each(function () {
                                if (parseFloat(this.value) === parseFloat(pValue)) {
                                    lObj.val(this.value);
                                    $('select[name="' + lName + '"]').find('option[value="' + this.value + '"]').attr("selected", true);
                                    hasChanged = true;
                                }
                            });
                            lObj.val(pValue);
                        }
                    } else {
                        if (lObj.val() !== pValue) {
                            hasChanged = true;
                            lObj.val(pValue);
                        }
                    }
                    lObj.attr('data-cur', pValue);
                    if (hasChanged /*&& lObj.is(":visible")*/) {
                        // Needs to set invisible items, like Trouser buttons
                        if (fDebug) {
                            console.log(lName + ' changed to ' + pValue);
                        }
                        lObj.trigger('change');
                    }
                } catch (e) {
                    console.log('Exception:' + this.Prefix + pName);
                    console.error(e);
                }
            };

            this.SizeMatrix = function (chart, field, chest) {
                try {
                    if (chart && field && chest) {
                        if (sizeMatrixObj === undefined || sizeMatrixObj.sizeMatrix.length === 0) {
                            console.error('Error No Size Matrix');
                        } else {
                            /*
                                                    if(fDebug1){
                                                        console.log('chart:' + chart + ' field:' + field + ' chest:' + chest);
                                                        console.log(sizeMatrixMap[String(chart).toLowerCase() +
                                                                  String(chest).toLowerCase()][field]);
                                                    }
                                                    var idx = String(chart).toLowerCase() + String(chest).toLowerCase();
                                                    if(sizeMatrixMap[idx] !== undefined){
                                                        return sizeMatrixMap[idx][field];
                                                    }else{
                                                        return null;
                                                    }
                            */
                            var i = 0;
                            while (i < sizeMatrixObj.sizeMatrix.length) {
                                // if (fDebug1) {
                                // 	console.log(sizeMatrixObj.sizeMatrix[i].SIZETABLE);
                                // 	console.log(sizeMatrixObj.sizeMatrix[i].CHESTSEAT);
                                // }
                                if (String(sizeMatrixObj.sizeMatrix[i].SIZETABLE).toLowerCase() === String(chart).toLowerCase() && String(sizeMatrixObj.sizeMatrix[i].CHESTSEAT).toLowerCase() === String(chest).toLowerCase()) {
                                    // if (fDebug1) {
                                    // 	console.log('SizeValue : ' + sizeMatrixObj.sizeMatrix[i][field]);
                                    // }
                                    return sizeMatrixObj.sizeMatrix[i][field];
                                }
                                i++;
                            }

                        }
                    } else if (fDebug) {
                        console.debug('Chest not defined');
                    }
                } catch (e) {
                    console.error(e);
                }
            };

            this.SizeMatrixNet = function (chart, field, chest) {
                try {
                    var strReturn = '';
                    if (fDebug1) {
                        console.log('SizeMatrix: /scripts-pas/SizeMatrix.fsscript?field=' + field + '&table=' + chart + '&value=' + chest);
                    }

                    $.ajax({
                        type: 'post',
                        async: false,
                        dataType: 'json',
                        url: '/scripts-pas/SizeMatrix.fsscript?field=' + field + '&table=' + chart + '&value=' + chest,
                        data: {
                            "field": field,
                            "table": chart,
                            "value": chest
                        },
                        success: function (r) {
                            if (r.error !== true) {
                                if (fDebug1) {
                                    console.log('SizeValue : ' + r[0].sizevalue);
                                }
                                strReturn = r[0].sizevalue;
                            } else {
                                if (fDebug1) {
                                    console.log(r);
                                }
                            }
                        },
                        error: function (e) {
                            console.log('SizeMatrix Lookup Failure');
                            console.log(e);
                        }
                    });
                    return strReturn;
                } catch (e) {
                    console.error(e);
                }
            };
        } catch (e) {
            console.error(e);
        }
    }
}

var GOrderInfo = {
    AllowActive: false,
    AllowRun: false,
    AllowDraft: false,
    AllowEdit: false,
    AllowDelete: false,
    AllowCancel: false,
    AllowComment: false,
    NeedsVerify: false,
    AllowSubmit: false,
    AllowKeepSameLevel: false,

    lLevelSec: 0,
    get SecLevel() {
        return this.lLevelSec;
    },

    set SecLevel(pLevelSec) {
        this.lLevelSec = pLevelSec;
        this.AllowActive = (pLevelSec & 1) === 1;
        this.AllowRun = (pLevelSec & 2) === 2;
        this.AllowDraft = (pLevelSec & 4) === 4;
        this.AllowEdit = (pLevelSec & 8) === 8;
        this.AllowDelete = (pLevelSec & 16) === 16;
        this.AllowCancel = (pLevelSec & 32) === 32;
        this.AllowComment = (pLevelSec & 64) === 64;
        this.NeedsVerify = (pLevelSec & 128) === 128;
        this.AllowSubmit = (pLevelSec & 256) === 256;
        this.AllowKeepSameLevel = (pLevelSec & 512) === 512;
    },
    lLevel: 0,
    get Level() {
        return this.lLevel;
    },
    set Level(pLevel) {
        this.lLevel = pLevel;
    }
};

var fInternalChange = false;

// $('.cloththumb').click(function() {

// 	bootbox.dialog({
// 		message: '<img class="img-fluid alt="" src="https://www.etch-suits.co.uk/lambton/texture/' + $(this).attt('data-clothguid') + '.jpg">',
// 		buttons: {
// 			success: {
// 				label: translator.get("Close"),
// 				className: "btn-default"
// 			}
// 		}
// 	});

// });

$.fn.safeUrl = function (args) {
    var that = this;
    if ($(that).attr('data-safeurl') && $(that).attr('data-safeurl') === 'found') {
        return that;
    } else {
        $.ajax({
            url: args.wanted,
            type: 'HEAD',
            error: function () {
                $(that).attr('src', args.rm);
            },
            success: function () {
                $(that).attr('src', args.wanted);
                $(that).attr('data-safeurl', 'found');
            }
        });
    }
    return that;
};

$.fn.removeUnicode = function () {
    var that = this;
    if ($(this).is('textarea')) {
        if (fDebug) {
            var str = $(that).val();
            for (var n = 0, l = str.length; n < l; n++) {
                var hex = Number(str.charCodeAt(n)).toString(16);
                console.debug(hex);
            }
        }
        $(that).val($(that).val().replace(/[\u2044]/gi, '/')); // Slash
        $(that).val($(that).val().replace(/[\uFFFD]/gi, '?')); // Questionmark
        $(that).val($(that).val().replace(/[\uFFFF]/gi, '')); // Invalid
        $(that).val($(that).val().replace(/[\u2013]/gi, '-')); // Dash
        $(that).val($(that).val().replace(/[\u2022]/gi, '·')); // Bullet
        $(that).val($(that).val().replace(/[\u201c]/gi, '"')); // Left Double Quote
        $(that).val($(that).val().replace(/[\u201d]/gi, '"')); // Right Double Quote
        $(that).val($(that).val().replace(/[\u2018]/gi, '\'')); // Left Single Quote
        $(that).val($(that).val().replace(/[\u2019]/gi, '\'')); // Right Single Quote
        //$(that).val($(that).val().replace(/[^!-@a-z0-9{-ÿ]/gi, '')); // Strip all non ASCII \x21-\xFF
    }
};

function lookupFullClothString(p) {
    var recg = p.val();
    var lDiv = $('#div' + p.attr('data-targetdiv'));
    var lHTML = $('#' + p.attr('data-targetdiv'));
    var lHTMLImage = $('#' + p.attr('data-targetimage'));

    var lDivBal = $('#div' + p.attr('data-targetbalance'));
    var lHTMLBal = $('#' + p.attr('data-targetbalance'));

    var lDivSpecial = $('#div' + p.attr('data-targetspecial'));
    var lHTMLSpecial = $('#' + p.attr('data-targetspecial'));

    var lDivStockQty = $('#div' + p.attr('data-targetstockqty'));
    var lHTMLStockQty = $('#' + p.attr('data-targetstockqty'));

    //console.log('Looking Up:'+recg);
    var ffOrder = $("#FFORDER").data("fforder");

    $.ajax({
        type: 'post',
        dataType: 'json',
        url: '/scripts-pas/getclothstr.fsscript?recg=' + recg + '&IDRetailer=' + fIDRetailerOrder + '&IDCollection=' + fIDCollectionOrder + '&fforder=' + (ffOrder.toUpperCase() === 'TRUE' || $('#chkMainClothIsFF').prop('checked') === true),
        data: {
            "recg": recg,
            "IDRetailer": fIDRetailerOrder,
            "IDCollection": fIDCollectionOrder,
            "fforder": (ffOrder.toUpperCase() === 'TRUE' || $('#chkMainClothIsFF').prop('checked') === true)
        },
        success: function (r) {
            if (fDebug) {
                console.debug('lookupFullClothString : Success ');
                console.debug(r);
            }
            var lCLOTHRECG = r.CLOTHRECG;
            var lCLOTHSTR = r.CLOTHSTR;

            $.ajax({
                type: 'HEAD',
                url: '/clothimagespot/' + r.CLOTHRECG + '.jpg',
                success: function () {
                    lHTMLImage.html('<a href="#" class="clothimagepreview" data-url="/clothimagespot/' + lCLOTHRECG + '.jpg"><img class="img-responsive img-rounded img-thumbnail" alt="' + lCLOTHSTR + '" src="/clothimagespot/' + lCLOTHRECG + '.jpg"></a>');

                    $(".clothimagepreview").unbind('click').on('click', function (e) {
                        e.preventDefault();
                        var lURL = $(this).attr('data-url');
                        if (fDebug) {
                            console.log(lURL);
                        }
                        toastr.options = {
                            "closeButton": true,
                            "debug": false,
                            "newestOnTop": true,
                            "progressBar": true,
                            "positionClass": "toast-top-full-width",
                            "preventDuplicates": false,
                            "showDuration": "300",
                            "hideDuration": "1000",
                            "timeOut": "0",
                            "iconClasses": {
                                error: '',
                                info: '',
                                success: '',
                                warning: ''
                            },
                            "extendedTimeOut": "1000",
                            "showEasing": "easeOutBounce",
                            "hideEasing": "easeInBack",
                            "closeEasing": "easeInBack",
                            "showMethod": "fadeIn",
                            "hideMethod": "fadeOut"
                        };
                        toastr.info('<img class="img-responsive center-block" src="' + lURL + '" width="50%" style="width:50%;">', '');
                    });
                },
                error: function () {
                    $.ajax({
                        type: 'HEAD',
                        url: 'https://www.etch-suits.co.uk/lambton/texture/' + r.CLOTHRECG + '.jpg',
                        success: function () {
                            //					lHTMLImage.html('<a href="https://www.etch-suits.co.uk/lambton/texture/' + lCLOTHRECG + '.jpg" target="_new"><img class="img-responsive img-rounded img-thumbnail" alt="' + lCLOTHSTR + '" src="https://www.etch-suits.co.uk/lambton/texture/' + lCLOTHRECG + '.jpg" width="100%"></a>');
                            lHTMLImage.html('<a href="#" class="clothimagepreview" data-url="https://www.etch-suits.co.uk/lambton/texture/' + lCLOTHRECG + '.jpg"><img class="img-responsive img-rounded img-thumbnail" alt="' + lCLOTHSTR + '" src="https://www.etch-suits.co.uk/lambton/texture/' + lCLOTHRECG + '.jpg"></a>');

                            $(".clothimagepreview").unbind('click').on('click', function (e) {
                                e.preventDefault();
                                var lURL = $(this).attr('data-url');
                                if (fDebug) {
                                    console.log(lURL);
                                }
                                toastr.options = {
                                    "closeButton": true,
                                    "debug": false,
                                    "newestOnTop": true,
                                    "progressBar": true,
                                    "positionClass": "toast-top-full-width",
                                    "preventDuplicates": false,
                                    "showDuration": "300",
                                    "hideDuration": "1000",
                                    "timeOut": "0",
                                    "iconClasses": {
                                        error: '',
                                        info: '',
                                        success: '',
                                        warning: ''
                                    },
                                    "extendedTimeOut": "1000",
                                    "showEasing": "easeOutBounce",
                                    "hideEasing": "easeInBack",
                                    "closeEasing": "easeInBack",
                                    "showMethod": "fadeIn",
                                    "hideMethod": "fadeOut"
                                };
                                toastr.info('<img class="img-responsive center-block" src="' + lURL + '" width="50%" style="width:50%;">', '');
                            });
                        },
                        error: function () {
                            lHTMLImage.html('');
                        }
                    });
                }
            });
            lHTML.html(lCLOTHSTR);
            lDiv.removeClass('hidden').addClass('show');
            fInternalChange = true;
            if (r.IsFactory) {
                $('#chkMainClothFactory').bootstrapToggle('on');
                $('#chkMainClothIsFF').bootstrapToggle('on');
                // $("input[name='chkMainClothFactory']").bootstrapSwitch("state",true);
                // $("input[name='chkMainClothIsFF']").bootstrapSwitch("state",true);
            }
            if (r.FullyFactored && ffOrder.toUpperCase() === 'TRUE') {
                $('#chkMainClothIsFF').bootstrapToggle('on');
                //				$("input[name='chkMainClothIsFF']").bootstrapSwitch("state",true);
            }
            fInternalChange = false;

            if (r.SPECIAL !== '' && r.SPECIAL !== undefined) {
                lDivSpecial.removeClass('hidden').addClass('show');
                lHTMLSpecial.html('<center><b>' + r.SPECIAL + '</b></center>');
            } else {
                lDivSpecial.removeClass('show').addClass('hidden');
                lHTMLSpecial.html('');
            }

            if (r.StockQty !== undefined && r.StockQty !== '') {
                if (parseFloat(r.StockQty) < 2.0) {
                    lHTMLStockQty.html('<center>Factory Held Cloth - Very Low Stock, Please Contact Cloth Department</center>');
                } else {
                    lHTMLStockQty.html('<center>Factory Held Cloth - Current Stock Qty = ' + r.StockQty + 'm</center>');
                }
                lDivStockQty.removeClass('hidden').addClass('show');
            } else {
                lDivStockQty.removeClass('show').addClass('hidden');
                lHTMLStockQty.html('');
            }

            if ((r.DTStockETA !== '') && (parseInt(r.Balance) < 5)) {
                lHTMLBal.html('<center><b>' + translator.get('Stock Due : ') + r.DTStockETA + '</b></center>');
                lDivBal.removeClass('hidden').addClass('show');
                $(".bntok").attr("disabled", "disabled");
            } else if (r.Discontinued) {
                if (r.DTDiscontinued) {
                    lHTMLBal.html('<center><b>' + translator.get('Discontinued : ') + r.DTDiscontinued + '</b></center>');
                } else {
                    lHTMLBal.html('<center><b>' + translator.get('Discontinued') + '</b></center>');
                }
                lDivBal.removeClass('hidden').addClass('show');
                $(".bntok").attr("disabled", "disabled");
            } else {
                switch (parseInt(r.Balance)) {
                    case 0:
                        lHTMLBal.html('<center><b>' + translator.get('Discontinued') + '</b></center>');
                        lDivBal.removeClass('hidden').addClass('show');
                        $(".bntok").attr("disabled", "disabled");
                        break;
                    case 1:
                        lHTMLBal.html('<center><b>' + translator.get('Out of Stock. ETA Up to 4 weeks') + '</b></center>');
                        lDivBal.removeClass('hidden').addClass('show');
                        break;
                    case 2:
                        lHTMLBal.html('<center><b>' + translator.get('Out of Stock. ETA 5+ weeks') + '</b></center>');
                        lDivBal.removeClass('hidden').addClass('show');
                        break;
                    case 3:
                        lHTMLBal.html('<center><b>' + translator.get('Contact Cloth Dept') + '</b></center>');
                        lDivBal.removeClass('hidden').addClass('show');
                        $(".bntok").attr("disabled", "disabled");
                        break;
                    case 4:
                        lHTMLBal.html('<center><b>' + translator.get('Queried') + '</b></center>');
                        lDivBal.removeClass('hidden').addClass('show');
                        break;
                    case 8:
                    case 9:
                        if ((ffOrder.toUpperCase() === 'TRUE') && (
                            $('#chkHouseBunch').prop('checked') === true ||
                            $('#chkMainClothIsFF').prop('checked') === true)) {
                            lHTMLBal.html('<center><b>' + translator.get('Sorry you can’t order this as Fully Factored, either re-enter as CMT or select another cloth, do not put details in cloth comments or the order will be sent back for a new order entering.') + '</b></center>');
                            lDivBal.removeClass('hidden').addClass('show');
                            $(".bntok").attr("disabled", "disabled");
                        } else if ((ffOrder.toUpperCase() !== 'TRUE') &&
                            ($('#chkMainClothIsFF').prop('checked') === true)) {
                            lHTMLBal.html('<center><b>' + translator.get('Sorry you can’t order this cloth as Fully Factored, please select another cloth, do not put details in cloth comments or the order will be sent back for a new order entering.') + '</b></center>');
                            lDivBal.removeClass('hidden').addClass('show');
                            $(".bntok").attr("disabled", "disabled");
                        } else {
                            lHTMLBal.html('<center><b>' + translator.get('Sorry you can only order this as Fully Factored. Please re-enter as a Fully Factored Order. Please do not put details in cloth comments or the order will be sent back for a new order entering.') + '</b></center>');
                            lDivBal.removeClass('hidden').addClass('show');
                            $(".bntok").attr("disabled", "disabled");
                        }
                        break;
                    default:
                        lDivBal.removeClass('show').addClass('hidden');
                        break;
                }
            }
        },
        error: function () {
            lDiv.removeClass('show').addClass('hidden');
            lDivBal.removeClass('show').addClass('hidden');
            if (fDebug) {
                console.debug('lookupFullClothString Failure');
            }
        }
    });
}


var clothList;
var fClothTimer;
//var fClearTimer;

function clothLookUpKeyUp() {
    var lQuery = $("#clothlookup").val();

    $("#clothcount").html('Searching <i class="fa fa-spinner fa-pulse fa-fw"></i>');

    if (fClothTimer) {
        clearTimeout(fClothTimer);
    }

    $("#divCLOTHSTR").removeClass('show').addClass('hidden');
    $("#divBALANCESTR").removeClass('show').addClass('hidden');
    $("#divSPECIALSTR").removeClass('show').addClass('hidden');

    fClothTimer = setTimeout(function () {
        var lff = 'false';
        var lfactory = 'false';
        var lHouse = 'false';
        var lMerchantType = $("#MTYPE").val();

        if ($('#chkHouseBunch').prop('checked') === true ||
            $('#chkMainClothIsFF').prop('checked') === true) {
            lff = 'true';
        } else {
            lff = 'false';
        }
        if ($('#chkHouseBunch').prop('checked') === true) {
            lHouse = 'true';
        } else {
            lHouse = 'false';
        }
        if ($('#chkMainClothFactory').prop('checked') === true) {
            lfactory = 'true';
        } else {
            lfactory = 'false';
        }

        if (lQuery !== '') {
            $("#clothcount").html('Searching <i class="fa fa-spinner fa-pulse fa-fw"></i>');
            $("#CLOTHGUID").attr("disabled", "disabled");
            var toPostData = {
                "query": lQuery,
                "ctype": lMerchantType,
                "ff": lff,
                "house": lHouse,
                "factory": lfactory,
                "IDRetailer": fIDRetailerOrder,
                "IDCollection": fIDCollectionOrder
            };
            var lLookup = "?query=" + lQuery + "&ctype=" + lMerchantType + "&ff=" + lff + "&house=" + lHouse + "&factory=" + lfactory + "&IDRetailer=" + fIDRetailerOrder + "&IDCollection=" + fIDCollectionOrder;

            if (fDebug) {
                console.debug(lLookup);
            }
            $.ajax({
                type: "post",
                url: '/scripts-pas/clothslookup.fsscript' + lLookup,
                data: toPostData,
                dataType: "json",
                cache: false,
                success: function (data) {
                    clothList = data;
                    var lList = '<option value="" selected>' + translator.get('Pick Cloth') + '</option>';
                    if (clothList.length > 0 && clothList[0].CLOTHGUID !== undefined) {
                        for (var element in data) {
                            if (clothList[element].CLOTHGUID !== undefined) {
                                lList = lList + '<option value="' + clothList[element].CLOTHGUID + '">' +
                                    clothList[element].CLOTHSTR + '</option>';
                            }
                        }
                        if ($("#clothlookup").val() === lQuery) {
                            $("#CLOTHGUID").removeAttr('disabled');
                            if (clothList.length >= 200) {
                                $("#clothcount").html(translator.get('Matching Cloths (more than 200)'));
                            } else {
                                $("#clothcount").html(translator.get('Matching Cloths') + ' (' + clothList.length + ')');
                            }
                        } else {
                            $("#clothcount").html('Searching <i class="fa fa-spinner fa-pulse fa-fw"></i>');
                            $("#CLOTHGUID").attr("disabled", "disabled");
                        }
                    } else {
                        $("#clothcount").html(translator.get('Matching Cloths'));
                        $("#clothmerchant").removeAttr('disabled');
                        $("#clothbunch").removeAttr('disabled');
                        $("#clothnumber").removeAttr('disabled');
                        lList = '<option value="" selected>' + translator.get('None Found') + '</option>';
                    }
                    $("#CLOTHGUID").html(lList);
                },
                error: function (e) {
                    console.log(e);
                    $("#CLOTHGUID").html('<option value="" selected>' + translator.get('None Found') + ' (JSON Error)</option>');
                    $("#clothcount").html(translator.get('Matching Cloths'));
                    $("#clothmerchant").removeAttr('disabled');
                    $("#clothbunch").removeAttr('disabled');
                    $("#clothnumber").removeAttr('disabled');
                    $("#clothcount").html('Error Finding Cloths');
                },
                complete: function () {
                    if ($("#clothlookup").val() === lQuery) {
                        $("#CLOTHGUID").removeAttr('disabled');
                        if (clothList.length >= 200) {
                            $("#clothcount").html(translator.get('Matching Cloths (more than 200)'));
                        } else {
                            $("#clothcount").html(translator.get('Matching Cloths') + ' (' + clothList.length + ')');
                        }
                    } else {
                        $("#clothcount").html('Searching <i class="fa fa-spinner fa-pulse fa-fw"></i>');
                        $("#CLOTHGUID").attr("disabled", "disabled");
                    }
                }
            });
        } else {
            //console.log('clothlookup:<blank>');
            $("#CLOTHGUID").html('<option value="" selected>' + translator.get('Pick Cloth') + '</option>');
            $("#clothcount").html(translator.get('Matching Cloths'));
            $("#clothmerchant").removeAttr('disabled');
            $("#clothbunch").removeAttr('disabled');
            $("#clothnumber").removeAttr('disabled');
            $("#clothmerchant").load('/scripts-pas/merchants.fsscript?type=0&ff=1');
            $("#clothmerchant").attr('disabled', 'disabled');
            $("#clothbunch").load('/scripts-pas/cloths.fsscript?type=bunches&id=ff&ctype=clothmerchant');
        }
        //verifyValues($);
    }, 350);
}

function clothLookFFSwitchChange() {
    if (!fInternalChange) {
        var OnlyHouse = (localStorage.getItem('cloud.' + cBrand + '.OnlyHouse') === "true") || ($("#FFORDER").data("fforder") === "TRUE");
        if ($('#chkHouseBunch').prop('checked') === true) {
            $('#chkMainClothIsFF').bootstrapToggle('disable');
        } else if (!OnlyHouse) {
            $('#chkMainClothIsFF').bootstrapToggle('enable');
        }
        clothLookUpKeyUp();
    }
}

function clothLookSwitchChange() {
    if (!fInternalChange) {
        var OnlyHouse = (localStorage.getItem('cloud.' + cBrand + '.OnlyHouse') === "true") || ($("#FFORDER").data("fforder") === "TRUE");
        if ($('#chkHouseBunch').prop('checked') === true) {
            $('#chkMainClothIsFF').bootstrapToggle('on');
            $('#chkMainClothIsFF').bootstrapToggle('disable');
        } else if (!OnlyHouse) {
            $('#chkMainClothIsFF').bootstrapToggle('enable');
        }

        // if($('input[name="chkHouseBunch"]').bootstrapSwitch('state')){
        //   	$('input[name="chkMainClothIsFF"]').bootstrapSwitch("state",true);
        // 	$('input[name="chkMainClothIsFF"]').bootstrapSwitch("disabled",true);
        // }else if(!OnlyHouse){
        // 	$('input[name="chkMainClothIsFF"]').bootstrapSwitch("disabled",false);
        // }
        clothLookUpKeyUp();
    }
}

function CLOTHGUIDchange() {
    var id = $("#CLOTHGUID").val();
    if (id !== '') {
        //		$("#clothmerchant").attr('disabled', 'disabled');
        //		$("#clothbunch").attr('disabled', 'disabled');
        //		$("#clothnumber").attr('disabled', 'disabled');
        //		$("#clothmerchant").val('');
        //		$("#clothbunch").val('');
        //		$("#clothnumber").val('');
        //$("#divMainClothImage").show();
        //$("#mainclothimage").attr('src', '/img/cloths/' + id + '_small.jpg');
        $(".bntok").removeAttr("disabled");
        lookupFullClothString($("#CLOTHGUID"));
    } else {
        $("#divMainClothImage").hide();
        //		$("#clothmerchant").removeAttr('disabled');
        //		$("#clothbunch").removeAttr('disabled');
        //		$("#clothnumber").removeAttr('disabled');
        $(".bntok").attr("disabled", "disabled");
    }
    //verifyValues($);
}

function getHelp(pPage, pID, pSuccess, pFailure) {
    $.ajax({
        type: 'post',
        url: pPage,
        data: {
            rguid: pID,
            cmd: 'view'
        },
        success: function (r) {
            if (typeof pSuccess === 'function') {
                pSuccess(r);
            }
        },
        error: function () {
            if (typeof pFailure === 'function') {
                pFailure(pID, pSuccess);
            }
        }
    });
}

function successHelp(r, title) {
    bootbox.dialog({
        message: r,
        title: title,
        buttons: {
            success: {
                label: translator.get("Close"),
                className: "btn-default"
            }
        }
    });
}

function showHelpPopup(event) {
    var lCol;
    var lIDWholesaler;
    //console.log('showHelpPopup');
    //console.log(event);
    var essay_id = event.currentTarget.id;
    var title = $('#' + essay_id).attr('data-title');

    if (typeof fIDWholesaler !== 'undefined') {
        lIDWholesaler = fIDWholesaler;
    } else {
        lIDWholesaler = '0';
    }

    if (typeof fIDCollectionOrder !== 'undefined') {
        lCol = fIDCollectionOrder;
    } else {
        lCol = 1;
    }

    var helpFile = essay_id.substr(4) + '.html';

    getHelp(
        '/help2/' + lIDWholesaler + '/' + lCol + '/' + helpFile,
        essay_id,
        successHelp,
        function (pID, pSuccess) {
            getHelp(
                '/help2/' + lIDWholesaler + '/1/' + helpFile,
                pID,
                pSuccess,
                function (pID, pSuccess) {
                    getHelp(
                        '/help2/0/' + lCol + '/' + helpFile,
                        pID,
                        pSuccess,
                        function (pID, pSuccess) {
                            getHelp(
                                '/help2/0/1/' + helpFile,
                                pID,
                                pSuccess,
                                function () {
                                    bootbox.dialog({
                                        message: '<h4><i class="fa fa-bug fa-lg"></i> ' + translator.get('Sorry Help Not Yet Available for this Option yet') + '</h4><small>' + '/help2/' + lIDWholesaler + '/' + lCol + '/' + helpFile + '</small>',
                                        title: title,
                                        buttons: {
                                            success: {
                                                label: translator.get("Close"),
                                                className: "btn-default"
                                            }
                                        }
                                    });

                                }
                            );
                        }
                    );
                }
            );
        }
    );
}

function showVideoPopup(event) {
    //console.log('showVideoPopup');
    var essay_id = event.currentTarget.id.substr(5);
    var lVideo = $('#video' + essay_id).attr('data-video-id');
    var lTitle = $('#video' + essay_id).attr('data-title');
    if (lVideo !== '' && lVideo !== undefined) {
        var some_html = '<div class="embed-responsive embed-responsive-16by9">';
        some_html += '<iframe class="embed-responsive-item" src="http://www.youtube.com/embed/' + lVideo +
            '" allowfullscreen="1" frameborder="0"></iframe>';
        some_html += '</div>';
        bootbox.dialog({
            message: some_html,
            title: lTitle,
            buttons: {
                success: {
                    label: "Close",
                    className: "btn-default"
                    /*,
                                        callback: function(){
                                            jQuery(".modal-backdrop, #myModal .close, #myModal .btn").live("click", function() {
                                                jQuery("#myModal iframe").attr("src", jQuery("#myModal iframe").attr("src"));
                                            });
                                        }*/
                }
            }
        });
    }
}

function setOrderTab($, pTab) {
    //console.log('Set Tab : ' + pTab);
    $('#garmenttabs a[href="#' + pTab + '"]').tab('show');
}

function isTheDivVisible(pID) {
    var divName = '#div' + pID;
    if ($(divName).hasClass('hidden') === true || $(divName).hasClass('show') === true) {
        if ($(divName).hasClass('hidden') === true && $(divName).hasClass('show') === false) {
            return false;
        } else {
            return true;
        }
    } else {
        if ($(divName).css('display') !== 'none') { // display: none;
            return true;
        } else {
            return false;
        }
    }
}

var detailsButtonClick = function (pButton) {
    var divName = pButton.attr('data-div');
    var txtOpen = pButton.attr('data-text-open');
    var txtClose = pButton.attr('data-text-close');

    if (typeof $('#div' + divName).velocity === 'function') {
        if (isTheDivVisible(divName)) {
            $('#div' + divName).velocity("transition.slideUpOut", {
                opacity: 0
            }, {
                display: "none"
            });
            pButton.html('<i class="fa fa-angle-double-down fa-lg"></i> ' + txtOpen);
        } else {
            $('#div' + divName).velocity("transition.slideDownIn", {
                opacity: 1
            }, {
                display: "block"
            });
            pButton.html('<i class="fa fa-angle-double-up fa-lg"></i> ' + txtClose);
        }
        $('.orderpagesold').velocity({
            height: "100%"
        }, {
            duration: "slow"
        });
    } else {
        if (isTheDivVisible(divName)) {
            $('#div' + divName).hide();
            pButton.html('<i class="fa fa-angle-double-down fa-lg"></i> ' + txtOpen);
        } else {
            $('#div' + divName).show();
            pButton.html('<i class="fa fa-angle-double-up fa-lg"></i> ' + txtClose);
        }
        $('.orderpagesold').show();
    }
    //$('.orderpages').height('100%');
    if (fIsLGFG) {
        $('#divVestStyleMisc').removeClass('show').addClass('hidden');
        $('#divJacketStyleMisc').removeClass('show').addClass('hidden');
    } else {
        $('#divVestStyleMisc').removeClass('hidden').addClass('show');
        $('#divJacketStyleMisc').removeClass('hidden').addClass('show');
    }
};

var selectCloth = function (pButton) {

    var button = pButton;
    var lTitle = '<i class="fa fa-search fa-2x"></i>  ' + button.attr('data-descr');
    var form_html =
        '<div class="row">' +
        '<div class="col-xs-12 col-sm-3 col-md-3 col-lg-3" style="vertical-align:top;padding-top:0px">' +
        '<label for="chkMainClothIsFF">' + translator.get('Fully Factored') + '</label><br/>' +
        '<input type="checkbox" id="chkMainClothIsFF" name="chkMainClothIsFF" data-toggle="toggle" data-on="' + translator.get('Yes') + '" data-off="' + translator.get('No') + '" value="true">' +
        '</div>' +
        '<div class="col-xs-12 col-sm-9 col-md-9 col-lg-9" style="vertical-align:top;padding-top:9px">' +
        '<small>' +
        '<p><b>' + translator.get('YES') + '</b> = ' + translator.get('one invoice from Lambton - CMT + Cloth - cloth can be Lambton House cloth or Merchant cloth. Filter shows all Lambton and Merchant cloths on Lambton cloth database. Cloths not found add manually to comments.') + '</p>' +
        '<p><b>' + translator.get('NO') + '</b> = ' + translator.get("one invoice from Lambton for CMT only - cloth ordered by Lambton on retailers own account. Filter shows all Lambton and Merchant cloths on Lambton cloth database. Cloths not found add manually to comments.") + '<p>' +
        '</small>' +
        '</div>' +
        '</div>' +

        '<div class="row" style="padding-bottom:0px;padding-top:0px">' +
        '<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">' +
        '<hr>' +
        '</div>' +
        '</div>' +

        '<div class="row">' +
        '<div class="col-xs-12 col-sm-3 col-md-3 col-lg-3" style="vertical-align:top;padding-top:0px">' +
        '<label for="chkHouseBunch">' + translator.get('House Cloths') + '</label><br/>' +
        '<input type="checkbox" id="chkHouseBunch" name="chkHouseBunch" data-toggle="toggle" data-on="' + translator.get('Yes') + '" data-off="' + translator.get('No') + '" value="true">' +
        '</div>' +
        '<div class="col-xs-12 col-sm-9 col-md-9 col-lg-9" style="vertical-align:top;padding-top:9px">' +
        '<small>' +
        '<p><b>' + translator.get('YES') + '</b> = ' + translator.get('filters/shows all cloth in Lambton livery (bunches/folders) held in factory or UK') + '</p>' +
        '</small>' +
        '</div>' +
        '</div>' +

        '<div class="row" style="padding-bottom:0px;padding-top:0px">' +
        '<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">' +
        '<hr>' +
        '</div>' +
        '</div>' +

        '<div class="row">' +
        '<div class="col-xs-12 col-sm-3 col-md-3 col-lg-3" style="vertical-align:top;padding-top:0px">' +
        '<label for="chkMainClothFactory">' + translator.get('Factory Cloths') + '</label><br/>' +
        '<input type="checkbox" id="chkMainClothFactory" name="chkMainClothFactory" data-toggle="toggle" data-on="' + translator.get('Yes') + '" data-off="' + translator.get('No') + '" value="true">' +
        '</div>' +
        '<div class="col-xs-12 col-sm-9 col-md-9 col-lg-9" style="vertical-align:top;padding-top:9px">' +
        '<small>' +
        '<p><b>' + translator.get('YES') + '</b> = ' + translator.get('filters/shows all cloth in Lambton livery (bunches/folders) held in factory only.') + ' ' + translator.get('This offers a faster service (this can be 20 working days, subject to seasonality/workload/un-hindered workflow. Guaranteed delivery dates can only be achieved by the express service which must be confirmed by your Account Manager') + '</p>' +
        '</small>' +
        '</div>' +
        '</div>' +

        '<div class="row" style="padding-bottom:0px;padding-top:0px">' +
        '<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">' +
        '<hr>' +
        '</div>' +
        '</div>' +
        '<div class="row" style="padding-bottom:0px;padding-top:0px">' +
        '<div class="col-xs-12 col-sm-3 col-md-3 col-lg-3">' +

        '<b>' + translator.get('Check / Stripe Matching') + '</b>' +
        '</div>' +
        '<div class="col-xs-12 col-sm-9 col-md-9 col-lg-9">' +
        '<small>' +
        '<p>' + translator.get('Please note due the number of figurations and options we offer on Jackets we do not guarantee to match the checks or stripes on the shoulders by default. Should this be a specific requirement please add it in comments and the garment will be reviewed, if suitable a chargeable special pattern can be created to match on the shoulders.') + '</p>' +
        '</small>' +
        '</div>' +
        '</div>' +

        '<div class="row" style="padding-bottom:0px;padding-top:0px">' +
        '<div class="col-xs-12 col-sm-3 col-md-3 col-lg-3">' +
        '<b>' + translator.get('Velvets') + '</b>' +
        '</div>' +
        '<div class="col-xs-12 col-sm-9 col-md-9 col-lg-9">' +
        '<small>' +
        '<p>' + translator.get('All PLAIN Velvets will be NAP UP (will feel slightly rough when stroking down), unless they are on a LAPEL FACING and then they will be NAP DOWN (will feel smooth when stroking down). All FANCY/PATTERNED Velvets NAP DOWN. Should you like the cloth treated in a different way, please put that information in comments.') + '</p>' +
        '</small>' +
        '</div>' +
        '</div>' +

        '<div class="row" style="padding-bottom:0px;padding-top:0px">' +
        '<div class="col-xs-12 col-sm-3 col-md-3 col-lg-3">' +
        '<b>' + translator.get('Customer Supplied Cloth') + '</b>' +
        '</div>' +
        '<div class="col-xs-12 col-sm-9 col-md-9 col-lg-9">' +
        '<small>' +
        '<p>' + translator.get('Please can you ensure you clearly label the "Face Side" of any cloth sent in for order') + '</p>' +
        '</small>' +
        '</div>' +
        '</div>' +

        //'<div class="row" style="padding-bottom:0px;padding-top:0px">' +
        //'<div class="col-xs-12 col-sm-3 col-md-3 col-lg-3">' +
        //'<b>' + translator.get('Bateman & Ogden') + '</b>' +
        //'</div>' +
        //'<div class="col-xs-12 col-sm-9 col-md-9 col-lg-9">' +
        // '<small>' +
        // '<p>' + translator.get('For commercial reasons we are no longer able to offer Bateman &amp; Ogden fabrics fully factored. So please enter them as CMT and purchase on your own accounts.') + '</p>' +
        // '</small>' +
        //'</div>' +
        //'</div>' +

        '<div class="row" style="padding-bottom:0px;padding-top:0px">' +
        '<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">' +
        '<hr>' +
        '</div>' +
        '</div>' +

        '<div class="row" style="padding-bottom:5px;">' +
        '<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">' +
        '<label for="clothlookup">' + translator.get('Enter cloth number to search for and Press ENTER to search (Tip : Just use numbers in the search i.e. for LT90135, just search for 90135) or try searching for the bunch i.e Siena') + '</label>' +
        '<input type="text" class="form-control" id="clothlookup">' +
        '</div>' +
        '</div>' +
        '<div class="row" style="padding-bottom:0;">' +
        '<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">' +
        '<label id="clothcount">Matching Cloths</label>' +
        '<select class="form-control" id="CLOTHGUID" data-targetdiv="CLOTHSTR" data-targetbalance="BALANCESTR" data-targetimage="IMAGESTR" data-targetspecial="SPECIALSTR" data-targetstockqty="STOCKQTY">' +
        '<option value="" selected="">' + translator.get('Pick Cloth') + '</option>' +
        '</select>' +
        '</div>' +
        '</div>' +
        '<div class="row hidden alert alert-danger" id="divBALANCESTR" style="padding-top:2px;padding-bottom:2px;margin-left:0;margin-right:0;margin-top:10px;margin-bottom:0">' +
        '<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 clothbalancestr" id="BALANCESTR"></div>' +
        '</div>' +

        '<div class="row hidden alert alert-warning" id="divSPECIALSTR" style="padding-top:2px;padding-bottom:2px;margin-left:0;margin-right:0;margin-top:10px;margin-bottom:0">' +
        '  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 specialstr" id="SPECIALSTR"></div>' +
        '</div>' +

        '<div class="row hidden well" id="divCLOTHSTR" style="margin-left:0;margin-right:0;margin-top:10px;margin-bottom:0">' +
        '  <div class="col-xs-12 col-sm-6 col-md-9 col-lg-9 clothsearchstr" id="CLOTHSTR"></div>' +
        '  <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3 clothimagestr" id="IMAGESTR"></div>' +
        '</div>' +

        '<div class="row hidden bg-success" id="divSTOCKQTY" style="padding-top:2px;padding-bottom:2px;margin-left:0;margin-right:0;margin-top:10px;margin-bottom:0">' +
        '  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 stockqty" id="STOCKQTY"></div>' +
        '</div>' +

        '<input type="hidden" id="MTYPE" name="MTYPE" value="' + button.data('merchanttype') + '">' +
        '<script type="text/javascript">' +
        '$(document).ready(function() { ' +
        '  var OnlyHouse = localStorage.getItem("cloud." + cBrand + ".OnlyHouse") === "true";' +
        '  $(".bntok").attr("disabled","disabled");' +
        '  $("#chkHouseBunch").bootstrapToggle("state",false);' +
        '  if(OnlyHouse || ($("#FFORDER").data("fforder") === "TRUE")){' +
        '      $("#chkMainClothIsFF").bootstrapToggle("on");' +
        '  }else{' +
        '      $("#chkMainClothIsFF").bootstrapToggle("off");' +
        '  }' +
        '  $("#chkMainClothFactory").bootstrapToggle("off");' +
        '  $("#chkHouseBunch").on("change", clothLookSwitchChange);' +
        '  $("#chkMainClothIsFF").on("change", clothLookFFSwitchChange);' +
        '  $("#chkMainClothFactory").on("change", clothLookSwitchChange);' +
        '  $("#clothlookup").advclothsearch({clothCount:$("#clothcount")});' +

        '  $("#CLOTHGUID").on("change", CLOTHGUIDchange);' +
        '  $("#CLOTHGUID").attr("disabled","disabled");' +
        '  $("#clothlookup").focus();' +
        '  if(OnlyHouse){' +
        '  		$("#chkMainClothIsFF").bootstrapToggle("on");' +
        '		$("#chkMainClothIsFF").bootstrapToggle("disable");' +
        '  		$("#chkHouseBunch").bootstrapToggle("on");' +
        '		$("#chkHouseBunch").bootstrapToggle("disable");' +
        //		'  		$("#chkMainClothFactory").bootstrapToggle("state",true);' +
        //		'		$("#chkMainClothFactory").bootstrapToggle("disabled",true);' +
        '  }' +
        '  if($("#FFORDER").data("fforder") === "TRUE"){' +
        '  		$("#chkMainClothIsFF").bootstrapToggle("on");' +
        '		$("#chkMainClothIsFF").bootstrapToggle("disable");' +
        //		'  		$("#chkHouseBunch").bootstrapToggle("state",true);' +
        '		$("#chkHouseBunch").bootstrapToggle("enabled");' +
        //		'  		$("#chkMainClothFactory").bootstrapToggle("state",true);' +
        //		'		$("#chkMainClothFactory").bootstrapToggle("disabled",true);' +
        '  }' +
        '  clothLookSwitchChange();' +
        '});' +
        '</script>';
    var box = bootbox.dialog({
        message: form_html,
        title: lTitle,
        buttons: {
            success: {
                label: "OK",
                className: "btn-primary bntok",
                callback: function () {
                    var lCGUID = $("#CLOTHGUID").val();
                    var lField = $("#" + button.data('field'));
                    lField.val($("#CLOTHSTR").html());
                    lField.attr('data-cguid', lCGUID);
                    if ($('#chkMainClothIsFF').prop('checked') === true) {
                        lField.attr('data-ff', 'TRUE');
                    } else {
                        lField.attr('data-ff', 'FALSE');
                    }
                    if (fDebug) {
                        console.debug(button);
                        console.debug(button.data('field'));
                        console.debug(lField);
                        console.debug(lCGUID);
                        console.debug($("#CLOTHGUID").val());
                        console.debug($("#CLOTHSTR").html());
                        console.debug($("#BALANCESTR").html());
                    }
                    lField.change();
                    var lMechantType = button.attr('data-merchanttype');
                    $("#editorderform *").filter(':input').each(function () {
                        var realName = $(this).attr('id');
                        if (typeof realName !== 'undefined') {
                            var lMerch = $(this).attr('data-merchanttype');
                            if ((lMechantType === lMerch) /*||
                                ((fIDCollectionOrder === 9) && (lMerch === '1' || lMerch === '9'))*/) {
                                var lNew = $("#" + $(this).attr('data-field'));
                                if (lNew && typeof lNew !== 'undefined' && typeof lNew.attr('id') !== 'undefined' && lNew.attr('id') !== lField.attr('id')) {
                                    if (fDebug) {
                                        console.log('Setting:' + lNew.attr('id'));
                                    }
                                    lNew.val($("#CLOTHSTR").html());
                                    lNew.attr('data-cguid', lCGUID);
                                    if ($('#chkMainClothIsFF').prop('checked') === true) {
                                        lField.attr('data-ff', 'TRUE');
                                    } else {
                                        lField.attr('data-ff', 'FALSE');
                                    }
                                    lNew.change();
                                }
                            }
                        }
                    });
                }
            },
            cancel: {
                label: "Cancel",
                className: "btn-default",
                callback: function () { }
            }
        }
    });
    box.bind('shown.bs.modal', function () {
        $("#clothlookup").focus();
    });
};

function checkSubmit() {
    if ((GOrderInfo.AllowSubmit || GOrderInfo.AllowKeepSameLevel) && GOrderInfo.AllowRun && !fViewOnly) {
        if (localStorage.getItem('cloud.' + cBrand + '.TOPSII') === 'true' ||
            localStorage.getItem('cloud.' + cBrand + '.TOPS25') === 'true' ||
            localStorage.getItem('cloud.' + cBrand + '.TOPSIII') === 'true') {
            $('.submitorder').removeAttr('disabled');
        } else {
            if (fDebug) {
                console.debug('Order Entry Access TOPSII : ' + localStorage.getItem('cloud.' + cBrand + '.TOPSII') === 'true');
                console.debug('Order Entry Access TOPS25 : ' + localStorage.getItem('cloud.' + cBrand + '.TOPS25') === 'true');
                console.debug('Order Entry Access TOPSIII :' + localStorage.getItem('cloud.' + cBrand + '.TOPSIII') === 'true');
            }
        }
    } else {
        console.log('Submit Not Allowed');
    }
}

function checkDraft() {
    if (GOrderInfo.AllowDraft) {
        if (localStorage.getItem('cloud.' + cBrand + '.AllowDraft') === 'true') {
            $('.savedraft').removeAttr('disabled');
        } else {
            //console.log('AllowDraft Access: ' + localStorage.getItem('cloud.'+cBrand+'.AllowDraft'));
        }
    } else {
        console.log('No Draft Allowed');
    }
}

var verinputChange = function (pButton, pFunc) {
    try {
        if (pButton.isVisible() && pButton.hasClass('verinput')) {
            try {
                pButton.trigger("verifyme");
            } catch (e) {
                console.error("verifyme exception:" + pButton.attr('id'));
                console.error(e);
            }
            try {
                pButton.trigger("renderscene");
            } catch (e) {
                console.error("renderscene exception:" + pButton.attr('id'));
                console.error(e);
            }
        } else {
            pButton.setFailure(false);
        }
        //pButton.update();
        if (typeof ErrorObjectList !== 'undefined') {
            if (ErrorObjectList.count === 0) {
                $('.submitorder').attr('disabled', 'disabled');
                $('.savedraft').attr('disabled', 'disabled');
                $('.preview3dorder').removeAttr('disabled');
                if (localStorage.getItem('cloud.' + cBrand + '.DemoUser') === 'false') {
                    if ((GOrderInfo.Level < 1001) || GOrderInfo.Admin) {
                        checkSubmit();
                        checkDraft();
                    } else {
                        pButton.setFailure(true, translator.get('Order In Production'));
                    }
                } else {
                    pButton.setFailure(true, translator.get('Demo User - No Saving'));
                }
            } else {
                if (fDebug) {
                    console.debug('Errors:' + ErrorObjectList.count);
                    console.debug(ErrorObjectList.errors);
                }
                $('.preview3dorder').attr('disabled', 'disabled');
                if (localStorage.getItem('cloud.' + cBrand + '.DemoUser') === 'false') {
                    $('.submitorder').attr('disabled', 'disabled');
                    $('.savedraft').attr('disabled', 'disabled');
                    if ((GOrderInfo.Level < 1001) || GOrderInfo.Admin) {
                        checkDraft();
                    }
                } else {
                    $('.submitorder').attr('disabled', 'disabled');
                    $('.savedraft').attr('disabled', 'disabled');
                }
            }
            ErrorObjectList.update();
        } else {
            console.error('ErrorObjectList undefined');
        }
        if (typeof WarnObjectList !== 'undefined') {
            WarnObjectList.update();
        } else {
            console.error('WarnObjectList undefined');
        }
        if (typeof pFunc === 'function') {
            pFunc();
        }
    } catch (e) {
        console.error("verinput exception:" + pButton.attr('id'));
        console.error(e);
    }
};

function checkSubmitButtons() {
    try {
        if (typeof ErrorObjectList !== 'undefined') {
            if (ErrorObjectList.count === 0) {
                $('.submitorder').attr('disabled', 'disabled');
                $('.savedraft').attr('disabled', 'disabled');
                if (localStorage.getItem('cloud.' + cBrand + '.DemoUser') === 'false') {
                    if ((GOrderInfo.Level < 1001) || GOrderInfo.Admin) {
                        checkSubmit();
                        checkDraft();
                    } else {
                        //console.log('Order In Production');
                    }
                } else {
                    //console.log('Demo User - No Saving');
                }
            } else {
                //console.log('Errors:' + ErrorObjectList.count);
                //console.log(ErrorObjectList.errors);
                if (localStorage.getItem('cloud.' + cBrand + '.DemoUser') === 'false') {
                    $('.submitorder').attr('disabled', 'disabled');
                    $('.savedraft').attr('disabled', 'disabled');
                    if ((GOrderInfo.Level < 1001) || GOrderInfo.Admin) {
                        checkDraft();
                    } else {
                        //console.log('Order In Production');
                    }
                } else {
                    //console.log('Demo User - No Saving');
                    $('.submitorder').attr('disabled', 'disabled');
                    $('.savedraft').attr('disabled', 'disabled');
                }
            }
            ErrorObjectList.update();
        } else {
            console.error('ErrorObjectList undefined');
        }
        if (typeof WarnObjectList !== 'undefined') {
            WarnObjectList.update();
        } else {
            console.error('WarnObjectList undefined');
        }
    } catch (e) {
        console.error(e);
    }
}

function show3dPreview(p) {
    if (fDebug) { console.log('show3dPreview'); }
    try {
        if (typeof ErrorObjectList !== 'undefined') {
            ErrorObjectList.update();
        } else {
            console.error('ErrorObjectList undefined');
        }
        if (typeof WarnObjectList !== 'undefined') {
            WarnObjectList.update();
        } else {
            console.error('WarnObjectList undefined');
        }
        if (typeof p === 'function') {
            p();
        }
        previewOrder3D();
    } catch (e) {
        console.error(e);
    }
}

function showBasket() {
    //console.log('showBasket');
    if ($('#invoicesdiv').hasClass('show')) {
        if (typeof $('#invoicesdiv').velocity === 'function') {
            $('#invoicesdiv').velocity("transition.slideRightOut", 400, function () {
                $('#invoicesdiv').addClass('hidden').removeClass('show');
                $('#basketdiv').addClass('show').removeClass('hidden');
                $('#basketdiv').velocity("transition.slideLeftIn", 400);
            });
        } else {
            $('#invoicesdiv').addClass('hidden').removeClass('show');
            $('#basketdiv').addClass('show').removeClass('hidden');
        }
    }
    $('#main-title').html(translator.get('Basket Contents'));
}

var orderListClick = function () {
    if ($('#editordiv').hasClass('show')) {
        if ($('#CHK_AUTOSAVE').prop('checked') === false) {
            bootbox.confirm("<h3>" + translator.get("Are you sure you want to cancel these changes?") + "</h3>", function (result) {
                if (result === true) {
                    checkPageChange(previousPage);
                }
            });
        } else {
            checkPageChange(previousPage);
        }
    } else {
        checkPageChange(previousPage);
    }
};

function populateOrderValues(pLevel) {
    orderPages.forEach(function (element) {
        element.fieldvalues.length = 0;
    });
    $("#editorderform *").filter(':input').each(function () {
        var realName = $(this).attr('id');
        if (typeof realName !== 'undefined') {
            if (typeof $(this).attr('data-id') !== 'undefined') {
                var lPage = $(this).attr('data-page');
                if (typeof lPage !== 'undefined') {
                    var item;
                    if ($(this).attr('type') === 'checkbox') {
                        item = {
                            "name": $(this).attr('data-id'),
                            //							"value" : $('input[name="' + $(this).attr('id') + '"]').bootstrapSwitch('state'),
                            "value": $('#' + $(this).attr('id')).prop('checked'),
                            "cguid": $(this).attr('data-cguid'),
                            "ff": $(this).attr('data-ff'),
                            "merchanttype": $(this).attr('data-merchanttype')
                        };
                    } else {
                        item = {
                            "name": $(this).attr('data-id'),
                            "value": escape($(this).val()),
                            "cguid": $(this).attr('data-cguid'),
                            "ff": $(this).attr('data-ff'),
                            "merchanttype": $(this).attr('data-merchanttype')
                        };
                    }
                    orderPages[lPage].fieldvalues.push(item);
                    orderPages[lPage].level = pLevel;
                }
            }
            /*else{
                            var lStr = $(this).attr("id");
                            if((lStr.indexOf('help') < 0) &&
                               (lStr.indexOf('btn') < 0) &&
                               (lStr.indexOf('TTLAM') < 0) &&
                               (lStr.indexOf('ATLAM') < 0) &&
                               (lStr.indexOf('TSLAM') < 0) &&
                               (lStr.indexOf('TDLAM') < 0) &&
                               (lStr.indexOf('TLLAM') < 0) &&
                               (lStr.indexOf('CustomerGUID') < 0)
                               ){
                                console.log('Undefined:' + lStr);
                            }
                        }*/
        }
    });
}

function populateOrderValues3D(pLevel) {
    orderPages.forEach(function (element) {
        element.fieldvalues.length = 0;
    });
    $("#editorderform *").filter(':input').each(function () {
        var realName = $(this).attr('id');
        if (typeof realName !== 'undefined') {
            if (typeof $(this).attr('data-id') !== 'undefined') {
                var lPage = $(this).attr('data-page');
                if (typeof lPage !== 'undefined') {
                    var item;
                    if ($(this).attr('type') === 'checkbox') {
                        item = {
                            "name": $(this).attr('data-id'),
                            "file3d": $('option:selected', this).attr('data-file3d'),
                            //							"value" : $('input[name="' + $(this).attr('id') + '"]').bootstrapSwitch('state'),
                            "value": $('#' + $(this).attr('id')).prop('checked'),
                            "cguid": $(this).attr('data-cguid'),
                            "ff": $(this).attr('data-ff'),
                            "merchanttype": $(this).attr('data-merchanttype')
                        };
                    } else
                    /*if($(this).attr('type') === 'checkbox'){
                    }else*/ {
                        item = {
                            "name": $(this).attr('data-id'),
                            "file3d": $('option:selected', this).attr('data-file3d'),
                            "value": escape($(this).val()),
                            "cguid": $(this).attr('data-cguid'),
                            "ff": $(this).attr('data-ff'),
                            "merchanttype": $(this).attr('data-merchanttype')
                        };
                    }
                    orderPages[lPage].fieldvalues.push(item);
                    orderPages[lPage].level = pLevel;
                }
            }
        }
    });
}

function populateStyleValues(pType, pTable) {
    stylevalues = {}; //.length = 0;
    var lPFX = '';
    if (pTable === 'LAM_DRESSII') {
        lPFX = 'DR2_';
    } else if (pTable === 'LAM_SHIRTII') {
        lPFX = 'SRT2_';
    } else if (pTable === 'LAM_GILETII') {
        lPFX = 'G2_';
    } else if (pTable === 'LAM_SKIRTII') {
        lPFX = 'LS2_';
    } else if (pTable === 'LAM_VII') {
        lPFX = 'V2_';
    } else if (pTable === 'LAM_TRII') {
        lPFX = 'T2_';
    } else if (pTable === 'LAM_JKTII') {
        lPFX = 'J2_';
    }

    if (fDebug) {
        console.debug(pTable + '=' + lPFX);
    }

    $("#editorderform *").filter(':input').each(function () {
        var realName = $(this).attr('id');
        if (parseInt($(this).attr('data-fieldclass')) === parseInt(pType)) {
            if (typeof realName !== 'undefined') {
                if (typeof $(this).attr('data-id') !== 'undefined') {
                    var lPage = $(this).attr('data-page');
                    if (typeof lPage !== 'undefined') {
                        if ($(this).isVisible() && $(this).val()) {
                            var id = $(this).attr('data-id');
                            if (id.slice(0, lPFX.length) === lPFX) {
                                if ($(this).val() === 'on' ||
                                    $(this).val() === 'true' ||
                                    $(this).val() === 'yes' ||
                                    $(this).val() === 'off' ||
                                    $(this).val() === 'false' ||
                                    $(this).val() === 'no') {
                                    stylevalues[id] = $(this).prop('checked');
                                } else {
                                    stylevalues[id] = escape($(this).val());
                                }
                            }
                            //var item = {
                            //	"name"  : $(this).data('id'),
                            //	"value" : escape($(this).val())
                            //};
                            //stylevalues.push(item);
                        }
                    }
                }
            }
        }
    });
    if (fDebug) {
        console.debug(stylevalues);
    }
}

var clonePopup;

function doTheClone(pRGUID, pRET_ORD_ID, pIDCollection, pFF, pIDEmployee, pIDRetailer, pPrev) {
    ////$('.sm-loading').show();
    showLoadingPage();
    $.ajax({
        type: 'post',
        dataType: 'json',
        url: '/entry2/cloneorder.fsscript?rguid=' + pRGUID + '&RET_ORD_ID=' + pRET_ORD_ID + '&IDCollection=' + pIDCollection + '&FF=' + pFF + '&IDEmployee=' + pIDEmployee + '&IDRetailer=' + pIDRetailer,
        data: {
            "rguid": pRGUID,
            "RET_ORD_ID": pRET_ORD_ID,
            "IDCollection": pIDCollection,
            "FF": pFF,
            "IDEmployee": pIDEmployee,
            "IDRetailer": pIDRetailer
        },
        success: function (r) {
            showSuccess('Order Cloned');
            if (fDebug) {
                console.debug(r);
            }
            if (r.error !== true) {
                $('#editordiv').load('/entry2/editorder.fsscript?RGUID=' + r.rguid, function (response, status, xhr) {
                    if (status === 'success') {
                        if (r.oldcollection === 0) {
                            window.open('/entry2/vieworder.fsscript?RGUID=' + pRGUID, '_blank');
                        }
                        checkPageChange('editor', r.rguid, pPrev);
                        hideLoading();
                    } else {
                        //$('.sm-loading').hide();
                        hideLoading();
                        showError(xhr.statusText);
                        if (fDebug) {
                            console.debug(status);
                            console.debug(xhr);
                        }
                    }
                });
            } else {
                hideLoading();
                showError('Error Cloning Order : ' + r.message);
            }
        },
        error: function (e) {
            hideLoading();
            try {
                console.log('Clone Order Failure');
                console.log(e);
                var r = JSON.parse(e.responseText);
                if (fDebug) {
                    console.debug(r);
                }
                showError('Error Cloning Order : ' + r.message);
            } catch (error) {
                console.log(error);
            }
        }
    });
}

function checkSelectedEmployee() {
    if ((localStorage.getItem('cloud.' + cBrand + '.Administration') === "true") ||
        (localStorage.getItem('cloud.' + cBrand + '.RetailerAdmin') === "true") ||
        (localStorage.getItem('cloud.' + cBrand + '.EmployeeAdmin') === "true")) {
        if ($("#IDEmployee").val() !== '') {
            $("#divIDEmployeeClone").removeClass('has-error');
            return true;
        } else {
            $("#divIDEmployeeClone").addClass('has-error');
            return false;
        }
    } else {
        return true;
    }
}

function checkRef() {
    //console.log('checkref:' + $("#IDRetailer").val());
    var t = $(this);
    if (t.timer) {
        clearTimeout(t.timer);
    }
    if (t.val()) {
        //$("#divRET_ORD_ID").removeClass('has-success').removeClass('has-error');
        t.timer = setTimeout(function () {
            if (t.val() !== '') {
                $.ajax({
                    type: 'post',
                    dataType: 'json',
                    url: '/scripts-pas/checkref.fsscript?RET_ORD_ID=' + escape(t.val()) + '&IDRetailer=' + $("#IDRetailer").val(),
                    data: {
                        "RET_ORD_ID": t.val(),
                        "IDRetailer": $("#IDRetailer").val()
                    },
                    success: function (j) {
                        if (j.ok === true) {
                            if (checkSelectedEmployee()) {
                                clonePopup.find(".okbutton").removeAttr('disabled');
                                t.attr('data-free', 'true');
                                $("#divRET_ORD_IDClone").removeClass('has-error');
                            }
                        } else {
                            checkSelectedEmployee();
                            clonePopup.find(".okbutton").attr('disabled', 'disabled');
                            t.attr('data-free', 'false');
                            $("#divRET_ORD_IDClone").addClass('has-error');
                        }
                    },
                    error: function () {
                        checkSelectedEmployee();
                        t.attr('data-free', 'false');
                        $("#divRET_ORD_IDClone").addClass('has-error');
                    }
                });
            } else {
                checkSelectedEmployee();
            }
        }, 200);
    } else {
        checkSelectedEmployee();
        clonePopup.find(".okbutton").attr('disabled', 'disabled');
    }
}

function cloneOrder(pRGUID, pIDCollection, pIDRetailer, pPrev) {
    var lDialogLegacy =
        '<h3>Please Note</h3><h5>This is a legacy order, so only the Measurements and Figurations will be populated, the old order will be opened in another browser tab for your reference (please make sure you allow popups for this site).</h5>.' +
        '<input class="form-control" type="hidden" id="IDRetailer" name="IDRetailer" value="' + pIDRetailer + '">' +
        '<div class="form-group has-error" id="divIDEmployeeClone">' +
        '  <label class="control-label" for="IDEmployeeClone">Employee</label>' +
        '  <select class="form-control" id="IDEmployeeClone" name="IDEmployeeClone"></select>' +
        '</div>' +
        '<div class="form-group has-error" id="divRET_ORD_IDClone">' +
        '  <label class="control-label" for="RET_ORD_IDClone">New Reference Number</label>' +
        '  <input class="form-control" type="text" id="RET_ORD_IDClone" name="RET_ORD_IDClone">' +
        '</div>' +
        '<div class="form-group">' +
        '	<label class="control-label" for="chkFF">Fully Factored</label><br/>' +
        '	<input type="checkbox" id="chkFF" name="chkFF" data-toggle="toggle" data-on="Yes" data-off="No" value="true">' +
        '</div>' +
        '<div class="form-group">' +
        '  <label class="control-label" for="IDCollection">New Collection</label>' +
        '  <select class="form-control" id="IDCollection" name="IDCollection"></select>' +
        '</div>' +
        '<p>If you only see "Legacy Collection" in the list above, please ask customer services to add you to some of the new collections</p>';

    var lDialog2 =
        '<input class="form-control" type="hidden" id="IDRetailer" name="IDRetailer" value="' + pIDRetailer + '">' +
        '<div class="form-group has-error" id="divIDEmployeeClone">' +
        '  <label class="control-label" for="IDEmployeeClone">Employee</label>' +
        '  <select class="form-control" id="IDEmployeeClone" name="IDEmployeeClone"><option value=""><Please Select></option></select>' +
        '</div>' +
        '<div class="form-group has-error" id="divRET_ORD_IDClone">' +
        '  <label class="control-label" for="RET_ORD_IDClone">' + translator.get('New Reference Number') + '</label>' +
        '  <input class="form-control" type="text" id="RET_ORD_IDClone" name="RET_ORD_IDClone">' +
        '</div>' +
        '<div class="form-group">' +
        '	<label class="control-label" for="chkFF">' + translator.get('Fully Factored') + '</label><br/>' +
        '	<input type="checkbox" id="chkFF" name="chkFF" data-toggle="toggle" data-on="Yes" data-off="No" value="true">' +
        '</div>';

    if (pIDCollection !== 0) {
        bootbox.hideAll();
        clonePopup = bootbox.dialog({
            message: lDialog2,
            title: "<h2>" + translator.get('Clone Order') + "</h2>",
            buttons: {
                cancel: {
                    label: translator.get('Cancel'),
                    className: "btn-default cancelbutton",
                    callback: function () {
                        $(".command-new").removeAttr("disabled");
                    }
                },
                confirm: {
                    label: translator.get('OK'),
                    className: "btn-primary okbutton",
                    callback: function () {
                        var lRET_ORD_IDClone = $('#RET_ORD_IDClone').val();
                        var lIDEmployeeClone = 0;
                        //var lIDRetailer = $('#IDRetailer').val();
                        if ((localStorage.getItem('cloud.' + cBrand + '.Administration') === "true") ||
                            (localStorage.getItem('cloud.' + cBrand + '.RetailerAdmin') === "true") ||
                            (localStorage.getItem('cloud.' + cBrand + '.EmployeeAdmin') === "true")) {
                            lIDEmployeeClone = $('#IDEmployeeClone').val();
                        }
                        //		      	var lFF = $("input[name='chkFF']").bootstrapSwitch("state");
                        var lFF = $("#chkFF").prop("checked");
                        if (lRET_ORD_IDClone) {
                            doTheClone(pRGUID, lRET_ORD_IDClone, pIDCollection, lFF, lIDEmployeeClone, pIDRetailer, pPrev);
                        }
                    }
                }
            }
        });
        clonePopup.init(function () {
            setTimeout(function () {
                if (fDebug) {
                    console.debug("Loading Employees 1");
                }
                $("#RET_ORD_IDClone").on("keyup", checkRef);
                $("#divIDEmployeeClone").on("change", checkRef);
                $("#divIDEmployeeClone").hide();
                if ((localStorage.getItem('cloud.' + cBrand + '.Administration') === 'true') ||
                    (localStorage.getItem('cloud.' + cBrand + '.RetailerAdmin') === 'true') ||
                    (localStorage.getItem('cloud.' + cBrand + '.EmployeeAdmin') === 'true')) {
                    if (fDebug) {
                        console.debug("Loading Employees 2");
                    }
                    $("#divIDEmployeeClone").show();
                    $("#IDEmployeeClone").load("/scripts-pas/employeelist.fsscript?IDRetailer=" + pIDRetailer,
                        function () {
                            if (fDebug) {
                                console.debug("Loading Employees 3 (" + pIDRetailer + ")");
                            }
                        });
                } else {
                    if (fDebug) {
                        console.debug("Not Admin");
                        console.debug(localStorage.getItem('cloud.' + cBrand + '.Administration'));
                        console.debug(localStorage.getItem('cloud.' + cBrand + '.RetailerAdmin'));
                        console.debug(localStorage.getItem('cloud.' + cBrand + '.EmployeeAdmin'));
                    }
                }
                //				$("input[name='chkFF']").bootstrapSwitch();
                //				$("input[name='chkFF']").bootstrapSwitch("state",true);
                $("#chkFF").bootstrapToggle("on");
                var OnlyHouse = localStorage.getItem('cloud.' + cBrand + '.OnlyHouse') === "true";
                if (OnlyHouse) {
                    //					$("input[name='chkFF']").bootstrapSwitch("disabled",true);
                    $("#chkFF").bootstrapToggle("disable");
                } else {
                    $("#chkFF").bootstrapToggle("enable");
                    //					$("input[name='chkFF']").bootstrapSwitch("disabled",false);
                }
                $(this).find(".okbutton").attr("disabled", "disabled");
            }, 1000);
        });
    } else {
        clonePopup = bootbox.dialog({
            message: lDialogLegacy,
            title: "<h2>" + translator.get('Clone Order') + "</h2>",
            buttons: {
                cancel: {
                    label: translator.get('Cancel'),
                    className: "btn-default cancelbutton",
                    callback: function () {
                        $(".command-new").removeAttr("disabled");
                    }
                },
                confirm: {
                    label: translator.get('OK'),
                    className: "btn-primary okbutton",
                    callback: function () {
                        var lRET_ORD_IDClone = $('#RET_ORD_IDClone').val();
                        var lIDCollection = $('#IDCollection').val();
                        var lIDEmployeeClone = 0;
                        //var lIDRetailer = $('#IDRetailer').val();
                        if ((localStorage.getItem('cloud.' + cBrand + '.Administration') === "true") ||
                            (localStorage.getItem('cloud.' + cBrand + '.RetailerAdmin') === "true") ||
                            (localStorage.getItem('cloud.' + cBrand + '.EmployeeAdmin') === "true")) {
                            lIDEmployeeClone = $('#IDEmployeeClone').val();
                        }
                        //		      	var lFF = $("input[name='chkFF']").bootstrapSwitch("state");
                        var lFF = $("#chkFF").prop("checked") === true;
                        if (lRET_ORD_IDClone) {
                            doTheClone(pRGUID, lRET_ORD_IDClone, lIDCollection, lFF, lIDEmployeeClone, pIDRetailer, pPrev);
                        }
                    }
                }
            }
        });
        clonePopup.init(function () {
            $("#RET_ORD_IDClone").on("keyup", checkRef);
            $("#divIDEmployeeClone").on("change", checkRef);
            $("#divIDEmployeeClone").hide();
            $("#IDCollection").load("/scripts-pas/options.fsscript?q=6&IDRetailer=" + pIDRetailer);
            if ((localStorage.getItem('cloud.' + cBrand + '.Administration') === "true") ||
                (localStorage.getItem('cloud.' + cBrand + '.RetailerAdmin') === "true") ||
                (localStorage.getItem('cloud.' + cBrand + '.EmployeeAdmin') === "true")) {
                $("#divIDEmployeeClone").show();
                $("#IDEmployeeClone").load("/scripts-pas/employeelist.fsscript?IDRetailer=" + pIDRetailer);
            }
            //			$("input[name='chkFF']").bootstrapSwitch();
            //			$("input[name='chkFF']").bootstrapSwitch("state",true);
            $("#chkFF").bootstrapToggle("on");
            var OnlyHouse = localStorage.getItem('cloud.' + cBrand + '.OnlyHouse') === "true";
            if (OnlyHouse) {
                //				$("input[name='chkFF']").bootstrapSwitch("disabled",true);
                $("#chkFF").bootstrapToggle("disable");
            } else {
                //				$("input[name='chkFF']").bootstrapSwitch("disabled",false);
                $("#chkFF").bootstrapToggle("enable");
            }
            $(this).find(".btn-primary").attr("disabled", "disabled");
        });
    }
}

var changePopup;

function doTheChangeLevel(pRGUID, pLevel, pStart, pFunc) {
    showLoadingPage();
    var lURL = '/scripts-pas/liveutils.fsscript?type=2&rguid=' + pRGUID + '&level=' + pLevel;
    $.ajax({
        type: 'post',
        dataType: 'json',
        url: lURL,
        data: {
            "rguid": pRGUID,
            "start": pStart,
            "level": pLevel
        },
        success: function (r) {
            if (fDebug) {
                console.debug(r);
            }
            if (r.error !== true) {
                hideLoading();
                showSuccess('Order Level Changed');
                if (typeof pFunc === 'function') {
                    pFunc(r);
                }
            } else {
                hideLoading();
                showError('Error Changing Order Level: ' + r.message);
            }
        },
        error: function (e) {
            hideLoading();
            try {
                console.log('Clone Order Failure');
                console.log(e);
                console.log(lURL);
                showError('Error Changing Order Level : ' + e.message);
                if (typeof pFunc === 'function') {
                    pFunc(e);
                }
            } catch (error) {
                console.log(error);
            }
        }
    });
}

function changeLevel(pRGUID, pStart, pFunc) {
    var lDialog2 =
        '<div class="form-group has-error" id="divNextLevel">' +
        '  <label class="control-label" for="nextlevel">Next Level</label>' +
        '  <select class="form-control" id="nextlevel" name="nextlevel"><option value=""><Please Select></option></select>' +
        '</div>';
    bootbox.hideAll();
    changePopup = bootbox.dialog({
        message: lDialog2,
        title: "<h2>" + translator.get('Change Level') + "</h2>",
        buttons: {
            cancel: {
                label: translator.get('Cancel'),
                className: "btn-default cancelbutton",
                callback: function () {
                    //$(".command-new").removeAttr("disabled");
                }
            },
            confirm: {
                label: translator.get('OK'),
                className: "btn-primary okchange",
                callback: function () {
                    var lNextLevel = $('#nextlevel').val();
                    doTheChangeLevel(pRGUID, lNextLevel, pStart, pFunc);
                }
            }
        }
    });
    changePopup.init(function () {
        setTimeout(function () {
            var url = "/scripts-pas/liveutils.fsscript?type=1&start=" + pStart;
            if (fDebug) {
                console.debug("Loading Levels 1 = " + url);
            }
            $(".okchange").attr("disabled", "disabled");
            $("#nextlevel").load(url,
                function () {
                    if (fDebug) {
                        console.debug("Loading Levels 2");
                    }
                });
            $("#nextlevel").on('change', function () {
                if (fDebug) {
                    console.log('Next Level Change');
                }
                if ($("#nextlevel").val() !== 'Please Select') {
                    if (fDebug) {
                        console.log('Next Level Change OK');
                    }
                    $("#divNextLevel").removeClass('has-error');
                    $(".okchange").removeAttr("disabled");
                } else {
                    if (fDebug) {
                        console.log('Next Level Change Fail');
                    }
                    $("#divNextLevel").addClass('has-error');
                    $(".okchange").attr("disabled", "disabled");
                }
            });
        }, 1000);
    });
}

function orderComment(pRGUID) {
    var lDialog2 =
        '<input type="hidden" id="rguid" value="' + pRGUID + '">' +
        '<div class="form-group has-error" id="divOrderCommentTitle">' +
        '  <label class="control-label" for="commenttitle">Comment Text</label>' +
        '  <input type="text" id="commenttitle" class="form-control name="commenttitle"></textarea>' +
        '  <label class="control-label" for="commenttext">Additional Info</label>' +
        '  <textarea rows="3" id="commenttext" class="form-control name="commenttext"></textarea>' +
        '</div>';
    bootbox.hideAll();
    changePopup = bootbox.dialog({
        message: lDialog2,
        title: "<h2>" + translator.get('Add Order Comment') + "</h2>",
        buttons: {
            cancel: {
                label: translator.get('Cancel'),
                className: "btn-default cancelbutton",
                callback: function () {
                    //$(".command-new").removeAttr("disabled");
                }
            },
            confirm: {
                label: translator.get('OK'),
                className: "btn-primary okcomment",
                callback: function () {
                    var lTitle = escape($('#commenttitle').val());
                    var lComment = escape($('#commenttext').val());
                    var lRGUID = $('#rguid').val();
                    var lURL = '/scripts-pas/liveutils.fsscript?type=4&rguid=' + lRGUID + '&comment=' + lComment + '&title=' + lTitle;
                    if (fDebug) {
                        console.log(lURL);
                    }
                    $.ajax({
                        type: 'post',
                        url: lURL,
                        dataType: 'json',
                        data: {
                            "type": 4,
                            "rguid": lRGUID,
                            "title": lTitle,
                            "comment": lComment
                        },
                        success: function (r, status, xhr) {
                            if (r.result === true) {
                                showSuccess('Comment Added');
                            } else {
                                console.log(r);
                                console.log(status);
                                console.log(xhr);
                                showError(r.msg);
                            }
                        },
                        error: function (e) {
                            showError(e.responseText);
                            console.error('Add Comment Failure');
                            console.error(e);
                        }
                    });
                }
            }
        }
    });
    changePopup.init(function () {
        setTimeout(function () {
            if (fDebug) {
                console.log('Comment Loaded');
            }
            $(".okcomment").attr("disabled", "disabled");
            $('#commenttext').unbind().on('input change keyup paste', (event) => {
                if (fDebug) {
                    console.log('Comment Change');
                    console.log(event);
                }
                if ($('#commenttitle').val() !== '') {
                    $("#divOrderCommentTitle").removeClass('has-error');
                    $("#divOrderComment").removeClass('has-error');
                    $(".okcomment").removeAttr("disabled");
                } else {
                    $("#divOrderCommentTitle").addClass('has-error');
                    $("#divOrderComment").addClass('has-error');
                    $(".okcomment").attr("disabled", "disabled");
                }
            });
            $('#commenttitle').unbind().on('input change keyup paste', (event) => {
                if (fDebug) {
                    console.log('Title Change');
                    console.log(event);
                }
                if ($('#commenttitle').val() !== '') {
                    $("#divOrderCommentTitle").removeClass('has-error');
                    $("#divOrderComment").removeClass('has-error');
                    $(".okcomment").removeAttr("disabled");
                } else {
                    $("#divOrderCommentTitle").addClass('has-error');
                    $("#divOrderComment").addClass('has-error');
                    $(".okcomment").attr("disabled", "disabled");
                }
            });
        }, 1000);
    });
}
/*
var previewOrder3D = function() {

    //$('.sm-loading').show();
    showLoadingPage();

    var baseHTML =
        '<div class="row content">' +
        '<div class="col-sm-12 text-left" style="height:600px">' +
        '<div class="col-sm-12">' +
        '<div class="collapse navbar-collapse" id="myNavbar">' +
        '<ul class="nav navbar-nav" id="menus">' +
        '</ul>' +
        '</div>' +
        '</div>' +
        '<div area-control="LAM_SHIRTII" style="display: none;" class="center-block threeDConfigurator" id="configurator_images" >' +
        '<div class="loader-main loader" style="display: none;">' +
        '<img src="/img/loader_shirt.svg" class="shirt-icon">' +
        '<div class="loader-new">' +
        '<div class="side"></div>' +
        '<div class="side"></div>' +
        '<div class="side"></div>' +
        '<div class="side"></div>' +
        '<div class="side"></div>' +
        '<div class="side"></div>' +
        '<div class="side"></div>' +
        '<div class="side"></div>' +
        '</div>' +
        '</div>' +
        '</div>' +
        '<div area-control="LAM_JKTII" style="display: none;" class="center-block threeDConfigurator" id="configurator_images_LAM_JKTII" >' +
        '<div class="loader-main loader" style="display: none;">' +
        '<img src="/img/loader_shirt.svg" class="shirt-icon">' +
        '<div class="loader-new">' +
        '<div class="side"></div>' +
        '<div class="side"></div>' +
        '<div class="side"></div>' +
        '<div class="side"></div>' +
        '<div class="side"></div>' +
        '<div class="side"></div>' +
        '<div class="side"></div>' +
        '<div class="side"></div>' +
        '</div>' +
        '</div>' +
        '</div>' +
        '<div area-control="LAM_TRII" style="display: none;" class="center-block threeDConfigurator" id="configurator_images_LAM_TRII" >' +
        '<div class="loader-main loader" style="display: none;">' +
        '<img src="/img/loader_shirt.svg" class="shirt-icon">' +
        '<div class="loader-new">' +
        '<div class="side"></div>' +
        '<div class="side"></div>' +
        '<div class="side"></div>' +
        '<div class="side"></div>' +
        '<div class="side"></div>' +
        '<div class="side"></div>' +
        '<div class="side"></div>' +
        '<div class="side"></div>' +
        '</div>' +
        '</div>' +
        '</div>' +
        '<div area-control="LAM_VII" style="display: none;" class="center-block threeDConfigurator" id="configurator_images_LAM_VII" >' +
        '<div class="loader-main loader" style="display: none;">' +
        '<img src="/img/loader_shirt.svg" class="shirt-icon">' +
        '<div class="loader-new">' +
        '<div class="side"></div>' +
        '<div class="side"></div>' +
        '<div class="side"></div>' +
        '<div class="side"></div>' +
        '<div class="side"></div>' +
        '<div class="side"></div>' +
        '<div class="side"></div>' +
        '<div class="side"></div>' +
        '</div>' +
        '</div>' +
        '</div>' +
        '</div>' +
        '</div>' +
        '<script type="text/javascript" src="/js/jquery-min.js"></script>' +
        '<script type="text/javascript" src="/js/utils.js"></script>' +
        '<script type="text/javascript" src="/js/3DRender.js"></script>' +
        '<script type="text/javascript" src="/js/LAM_JKTII_Visualizer.js"></script>' +
        '<script type="text/javascript" src="/js/LAM_TRII_Visualizer.js"></script>' +
        '<script type="text/javascript" src="/js/LAM_VII_Visualizer.js"></script>' +
        '<script type="text/javascript">' +
        '$(document).ready(function() {' +
        '  var menu_id = MyJSONObject[0].refer;' +
        '  $("#myNavbar .menubar").removeClass("active");' +
        '  $(this).addClass("active");' +
        '  $(".threeDConfigurator").hide();' +
        '  $("#configurator_images_"+menu_id).show();' +
        '});' +
        '</script>';

    populateOrderValues3D(990);
    try {
        var lData = JSON.stringify(orderPages);
        if (fDebug) {
            console.log(lData);
        }
        bootbox.dialog({
            message: baseHTML,
            title: '3D View',
            buttons: {
                success: {
                    label: translator.get("Close"),
                    className: "btn-default"
                },
                loademodel
            }
        }).bind('shown.bs.modal', function() {
            bject = (lData);
            //$('.sm-loading').hide();
            hideLoading();
            // var menu_id = MyJSONObject[0].refer;
            // $("#myNavbar .menubar").removeClass("active");
            // $(this).addClass("active");
            // $(".threeDConfigurator").hide();
            // $("#configurator_images_"+menu_id).show();
        });

        //window.open('https://www.etch-suits.co.uk/3d/get3d.php?'+lData);

        // $.ajax({
        // 	type : 'post',
        // 	dataType: 'json',
        // 	url : 'https://www.etch-suits.co.uk/3d/get3d.php',
        // 	data : lData,
        // 	success : function(r)
        // 	{
        //               bootbox.dialog({
        //                   message: r,
        //                   title: '3D View',
        //                   buttons: {
        //                       success: {
        //                           label: translator.get("Close"),
        //                           className: "btn-default"
        //                       }
        //                   }
        //               });
        // 	},
        // 	error: function(jqXHR,textStatus,errorThrown) {
        // 		showError(translator.get('Error Previewing Order'));
        // 		console.log('Preview Order Failure');
        // 		console.log(jqXHR);
        // 		console.log(jqXHR.responseText);
        // 		console.log(textStatus);
        // 		console.log(errorThrown);
        // 	}
        // });

    } catch (e) {
        console.log('Preview Order Exception');
        console.error(e);
    }
};
*/
var submitOrderClick = function () {
    populateOrderValues(1001);
    $('.sm-saving').show();
    try {
        var lData = JSON.stringify(orderPages);
        if (fDebug) {
            console.log(lData);
        }
        $.ajax({
            type: 'post',
            dataType: 'json',
            url: '/entry2/updateorder.fsscript',
            data: lData,
            success: function (r) {
                if (fDebug) {
                    console.log(r);
                }
                if (r.error !== true) {
                    showSuccess(translator.get('Order Submitted'));
                    checkPageChange(previousPage);
                    $('.sm-saving').hide();
                    try {
                        if (fActiveGrid) {
                            fActiveGrid.bootgrid("reload");
                        }
                    } catch (e) {
                        console.error(e);
                    }
                } else {
                    $('.sm-saving').hide();
                    showError(translator.get('Error Submitting Order:' + r.error0));
                }
            },
            error: function (e) {
                $('.sm-saving').hide();
                showError(translator.get('Error Submitting Order'));
                console.log('Submit Order Failure');
                console.log(e);
            }
        });
    } catch (e) {
        console.error(e);
    }
};

var submitSameLevelClick = function () {
    populateOrderValues(GOrderInfo.Level);
    $('.sm-saving').show();
    try {
        var lData = JSON.stringify(orderPages);
        if (fDebug) {
            console.log(lData);
        }
        $.ajax({
            type: 'post',
            dataType: 'json',
            url: '/entry2/updateorder.fsscript',
            data: lData,
            success: function (r) {
                if (fDebug) {
                    console.log(r);
                }
                if (r.error !== true) {
                    showSuccess(translator.get('Order Submitted'));
                    checkPageChange(previousPage);
                    $('.sm-saving').hide();
                    try {
                        if (fActiveGrid) {
                            fActiveGrid.bootgrid("reload");
                        }
                    } catch (e) {
                        console.error(e);
                    }
                } else {
                    $('.sm-saving').hide();
                    showError(translator.get('Error Submitting Order:' + r.error0));
                }
            },
            error: function (e) {
                $('.sm-saving').hide();
                showError(translator.get('Error Submitting Order'));
                console.log('Submit Order Failure');
                console.log(e);
            }
        });
    } catch (e) {
        console.error(e);
    }
};

var saveDraftClick = function () {
    populateOrderValues(990);
    $('.sm-saving').show();
    try {
        var lData = JSON.stringify(orderPages);
        if (fDebug) {
            console.log(lData);
        }
        $.ajax({
            type: 'post',
            dataType: 'json',
            url: '/entry2/updateorder.fsscript',
            data: lData,
            success: function (r) {
                if (fDebug) {
                    console.log(r);
                }
                if (r.error !== true) {
                    showMessage(translator.get('Order Saved to Draft'));
                    checkPageChange(previousPage);
                    $('.sm-saving').hide();
                    try {
                        if (fActiveGrid) {
                            fActiveGrid.bootgrid("reload");
                        }
                    } catch (e) {
                        console.error(e);
                    }
                } else {
                    if (fDebug) {
                        console.debug(r);
                    }
                    $('.sm-saving').hide();
                    showError(translator.get('Error Saving Order to Draft:' + r.error0));
                }
            },
            error: function (e) {
                $('.sm-saving').hide();
                showError(translator.get('Error Saving Order to Draft'));
                console.log('Update Order Failure');
                console.log(e);
                console.log(e.responseText);
            }
        });
    } catch (e) {
        console.error(e);
    }
};

function saveOrderValues(action) {
    populateOrderValues(990);
    $('.sm-saving').show();
    try {
        var lData = JSON.stringify(orderPages);
        if (fDebug) {
            console.log(lData);
        }
        $.ajax({
            type: 'post',
            dataType: 'json',
            url: '/entry2/updateorder.fsscript',
            data: lData,
            success: function (r) {
                if (fDebug) {
                    console.log(r);
                }
                if (r.error !== true) {
                    $('.sm-saving').hide();
                } else {
                    $('.sm-saving').hide();
                    showError(translator.get('Error Saving Order to Draft:' + r.error0));
                }
                if (typeof (action) === 'function') {
                    action();
                }
            },
            error: function (e) {
                $('.sm-saving').hide();
                showError(translator.get('Error Saving Order to Draft'));
                console.log('Update Order Failure');
                console.log(e);
                console.log(e.responseText);
            }
        });
    } catch (e) {
        console.error(e);
    }
}

function checkButtons(id) {
    try {
        if (typeof id !== 'undefined') {
            var lTable = id.data('refer');
            var lItemID = id.data('itemid');
            var lStyle = $('#AT' + lTable + lItemID).val();
            if (lStyle !== null && typeof lStyle !== 'undefined') {
                lStyle = lStyle.toUpperCase();
                if (((fRoles & 1048576) === 1048576) && (lStyle.indexOf('LAM_STYLES') >= 0)) {
                    $('#TD' + lTable + lItemID).removeAttr('disabled');
                } else {
                    $('#TD' + lTable + lItemID).attr('disabled', 'disabled');
                }
                if ($('#AT' + lTable + lItemID).val()) {
                    $('#TL' + lTable + lItemID).removeAttr('disabled');
                } else {
                    $('#TL' + lTable + lItemID).attr('disabled', 'disabled');
                }
                if (parseInt($('#TT' + lTable + lItemID).val()) > 6) {
                    $('#TS' + lTable + lItemID).attr('disabled', 'disabled');
                } else {
                    $('#TS' + lTable + lItemID).removeAttr('disabled');
                }
            }
            //if(lStyle.indexOf(cCustomerProfile) >= 0)
        }
    } catch (e) {
        console.error(e);
    }
}

var templateTypesChange = function (pElement) {
    try {
        ///scripts-pas/stylelist.fsscript?table=&cguid=9347A5DB-B5F8-4584-938A-460E1FBC65A5&oguid=undefined&rguid=undefined&profile=false&classes=0&idcollection=0&idwholesaler=0
        // table=LAM_JACKET&cguid=E6476F90-7264-41C7-9555-3129E69ACE37&oguid=&profile=false&classes=1
        var lTable = pElement.attr('data-refer');
        var lItemID = pElement.attr('data-itemid');
        var lOGUID = $('#RGUID').data('rguid');
        var lCGUID = $('#CustomerGUID').val();
        var lClasses = parseInt(pElement.val());
        var lProfile = ((lClasses === 0) || (lClasses === 1) || (lClasses === 7) || (lClasses === 8)) && lTable !== '';
        var lData = 'table=' + lTable +
            '&cguid=' + lCGUID +
            '&oguid=' + lOGUID +
            '&rguid=' + lOGUID +
            '&profile=' + lProfile +
            '&classes=' + lClasses +
            '&idcollection=' + fIDCollectionOrder +
            '&idwholesaler=' + fIDWholesalerOrder;
        $('#AT' + lTable + lItemID).attr('disabled', 'disabled');
        $('.TemplateTypes').attr('disabled', 'disabled');
        $('#AT' + lTable + lItemID).html('<option value="">' + translator.get('Loading...') + '</option>');
        checkButtons(pElement);
        if (fDebug) {
            console.debug('Template Type Changed');
            console.debug(lData);
        }
        $('#AT' + lTable + lItemID).load('/scripts-pas/stylelist.fsscript?' + lData, function (response, status, xhr) {
            if (status === "error") {
                var msg = "Sorry but there was an error: ";
                console.log(msg + xhr.status + " " + xhr.statusText);
            }
            if (fDebug) {
                console.debug('Template Types Loaded');
            }
            checkButtons(pElement);
            if (fDebug) {
                console.debug('Buttons Checked');
            }
            $('#AT' + lTable + lItemID).removeAttr('disabled');
            $('.TemplateTypes').removeAttr('disabled');
        });

    } catch (e) {
        console.error(e);
    }
};

var availableTemplatesChange = function (pButton) {
    try {
        checkButtons(pButton);
    } catch (e) {
        console.error(e);
    }
};

var tplLoadClick = function (pButton) {
    var lButton = pButton;
    var lCustomerGUID = $('#CustomerGUID').val();
    //	var lStr = pButton.attr('id');
    var lItemID = pButton.attr('data-itemid');
    var lTable = pButton.attr('data-refer');
    var lTemplate = $('#AT' + lTable + lItemID).val();
    var lTableSrc = getToken(lTemplate, '|', 0);
    var lTableDst = getToken(lTemplate, '|', 1);
    var IGUID = getToken(lTemplate, '|', 2);
    //var lTable2 = getToken(lTemplate, '|', 0);
    var lClass = parseInt($('#TT' + lTable + lItemID).val());
    var lParams = [];
    var lError = false;
    var lErrorStr = '';
    var RGUID = $('#RGUID').data('rguid');

    if (fDebug) {
        console.debug('lTemplate:' + lTemplate);
        console.debug('IGUID:' + IGUID);
        console.debug('RGUID:' + RGUID);
        console.debug('lClass:' + lClass);
        console.debug('lTableSrc:' + lTableSrc);
        console.debug('lTableDst:' + lTableDst);
    }

    switch (lClass) {
        case 0: // Measurements
            if (fDebug) {
                console.debug('Measurements');
            }
            lParams = {
                "fororder": "true",
                "iguid": IGUID,
                "rguid": RGUID,
                "cguid": lCustomerGUID,
                "class": 0,
                "debug": "true",
                "tablesrc": lTableSrc,
                "tabledst": lTableDst
            };
            break;
        case 1: // Figuration
            if (fDebug) {
                console.debug('Figuration');
            }
            lParams = {
                "fororder": "true",
                "iguid": IGUID,
                "rguid": RGUID,
                "cguid": lCustomerGUID,
                "class": 1,
                "debug": "true",
                "tablesrc": lTableSrc,
                "tabledst": lTableDst
            };
            break;
        case 2: // Style
            if (fDebug) {
                console.debug('Style');
            }
            lParams = {
                "fororder": "true",
                "iguid": IGUID,
                "rguid": RGUID,
                "cguid": lCustomerGUID,
                "class": 2,
                "debug": "true",
                "tablesrc": lTableSrc,
                "tabledst": lTableDst
            };
            break;
        case 7: // Measurements & Figuration
            if (fDebug) {
                console.debug('Measurements & Figuration');
            }
            lParams = {
                "fororder": "true",
                "iguid": IGUID,
                "rguid": RGUID,
                "cguid": lCustomerGUID,
                "class": "7",
                "debug": "true",
                "tablesrc": lTableSrc,
                "tabledst": lTableDst
            };
            break;
        case 8: // Measurements, Figuration & Style
            if (fDebug) {
                console.debug('Measurements, Figuration & Style');
            }
            lParams = {
                "fororder": "true",
                "iguid": IGUID,
                "rguid": RGUID,
                "cguid": lCustomerGUID,
                "class": "8",
                "debug": "true",
                "tablesrc": lTableSrc,
                "tabledst": lTableDst
            };
            break;
        default:
            console.log('Unknown Class');
    }

    /*
        JSON.stringify([{
            "fororder": "true",
            "iguid": "FAA3E8E7-B250-4806-9966-E0109C9E390D",
            "rguid": "C131FC9B-B73E-4269-939E-912B416E3D8E",
            "cguid": "6F1AE435-9DCB-4346-96B3-5DB67CECF22F",
            "class": "7",
            "debug": "true",
            "tablesrc": "LAM_JKTII",
            "tabledst": "LAM_JKTII"
        }]);
    */

    if (IGUID !== '') {
        //$('.sm-loading').show();
        lButton.attr('disabled', 'disabled');
        lButton.html('<i class="fa fa-cog fa-spin fa-fw"></i> ' + translator.get('Loading') + '</button>');
        var lArr = [];
        lArr.push(lParams);
        var lData = JSON.stringify(lArr);
        if (fDebug) {
            console.debug('Arrays:');
            console.debug(lParams);
            console.debug(lArr);
            console.debug(lData);
        }
        $.ajax({
            type: 'post',
            url: '/entry2/import2.fsscript?' + lData,
            data: lData,
            success: function (r) {
                var lObj;
                try {
                    if (fDebug) {
                        console.debug('Returned JSON Object:');
                        console.debug(r);
                    }
                    //lObj = JSONstring.toObject(r);
                    lObj = JSON.parse(r);
                    if (fDebug) {
                        console.debug('Translated Object:');
                        console.debug(lObj);
                    }
                    if (typeof lObj.ERRORMESSAGE !== 'undefined') {
                        console.error(typeof lObj.ERRORMESSAGE);
                        if (fDebug) {
                            console.debug(lObj.ERRORMESSAGE);
                        }
                        lErrorStr = 'Error Loading Order : ' + lObj.ERRORMESSAGE;
                        lError = true;
                    } else {
                        $('#CHK_AUTOSAVE').attr('data-loading', 'true');
                        for (var name in lObj) {
                            try {
                                if (fDebug) {
                                    console.debug(lObj[name]);
                                }
                                if (lObj.hasOwnProperty(name)) {
                                    $("#" + name).removeClass('template_changed');
                                    $("#div" + name).removeClass('template_changed');
                                    if (name !== 'CGUID') {
                                        if ($("#" + name).attr('disabled') !== 'disabled' &&
                                            $("#" + name).prop('disabled') !== true) {
                                            if (lObj[name] === 'on' ||
                                                lObj[name] === 'off' ||
                                                typeof lObj[name] === 'boolean') {
                                                $("#" + name).trigger('visiblecheck');
                                                // if($('input[name="' + name + '"]').bootstrapSwitch('state') !== lObj[name]){
                                                if ($('#' + name).prop('checked') !== lObj[name]) {
                                                    if (fDebug) {
                                                        console.debug('name:' + name);
                                                        console.debug('old:' + $("#" + name).val());
                                                        console.debug('new:' + lObj[name]);
                                                    }
                                                    $("#div" + name).addClass('template_changed');
                                                }
                                                //											$('input[name="' + name + '"]').bootstrapSwitch('state', lObj[name]);
                                                if (lObj[name]) {
                                                    $('#' + name).bootstrapToggle('on');
                                                } else {
                                                    $('#' + name).bootstrapToggle('off');
                                                }
                                                $("#" + name).change();
                                            } else {
                                                if (typeof lObj[name] === 'number') {
                                                    $("#" + name).trigger('visiblecheck');
                                                    if ($("#" + name).val() !== lObj[name]) {
                                                        if (fDebug) {
                                                            console.debug('name:' + name);
                                                            console.debug('old:' + $("#" + name).val());
                                                            console.debug('new:' + lObj[name]);
                                                        }
                                                        $("#" + name).addClass('template_changed');
                                                    }
                                                    if ($("#" + name).attr('type') === 'number') {
                                                        $("#" + name).val(lObj[name]);
                                                    } else {
                                                        $("#" + name).fuzzySet(lObj[name]);
                                                    }
                                                    $("#" + name).change();
                                                } else if (lObj[name] !== '' && lObj[name] !== '0') {
                                                    if ($("#" + name).find('option[value="' + lObj[name] + '"]').prop('disabled') !== true) {
                                                        $("#" + name).trigger('visiblecheck');
                                                        if ($("#" + name).val() !== lObj[name]) {
                                                            if (fDebug) {
                                                                console.debug('name:' + name);
                                                                console.debug('old:' + $("#" + name).val());
                                                                console.debug('new:' + lObj[name]);
                                                            }
                                                            $("#" + name).addClass('template_changed');
                                                        }
                                                        $("#" + name).val(lObj[name]);
                                                        $("#" + name).selectOptions(lObj[name], true);
                                                        if (parseFloat(lObj[name]) > 0 || parseInt(lObj[name]) > 0) {
                                                            $("#" + name).fuzzySet(lObj[name]);
                                                        }
                                                        $("#" + name).change();
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            } catch (err) {
                                console.error('Error:' + err + ' (' + name + ')');
                            }
                        }
                        saveOrderValues(function () {
                            $('#CHK_AUTOSAVE').removeAttr('data-loading');
                        });
                    }
                } catch (err) {
                    lErrorStr = translator.get('Error Importing Template') + ' : ' + err;
                    lError = true;
                    console.error('Error:' + err);
                    console.error(r);
                }
                if (lError) {
                    showError(lErrorStr);
                } else {
                    var OrderID;
                    if (typeof lObj.ORDER_ID === 'string' && lObj.ORDER_ID !== 'ORDER_ID_1') {
                        OrderID = lObj.ORDER_ID;
                    } else if (typeof lObj.ORDER_NO === 'string' && lObj.ORDER_ID !== 'ORDER_NO_1') {
                        OrderID = lObj.ORDER_NO;
                    } else {
                        OrderID = '';
                    }
                    showMessage('<i class="fa fa-check"></i> ' + translator.get('Template') + ' ' + OrderID + ' ' + translator.get('Loaded'));
                }
                lButton.removeAttr('disabled');
                lButton.html('<i class="fa fa-folder-open-o fa-lg"></i> ' + translator.get('Load') + '</button>');
                //$('.sm-loading').hide();
                hideLoading();
                $('#T2_NEW_EXTLEN').bootstrapToggle('destroy');
            },
            error: function (request, status, error) {
                showError(request.responseText);
                console.log(request.responseText);
                console.log(status);
                console.log(error);
                lButton.removeAttr('disabled');
                lButton.html('<i class="fa fa-folder-open-o fa-lg"></i> ' + translator.get('Load') + '</button>');
                //$('.sm-loading').hide();
                hideLoading();
            }
        });
    }
};
/*
function replacer(key, value) {
// Filtering out properties
    if(fDebug){
        console.log('replacer:');
            console.log(value);
            console.log(typeof value);
    }
    if(typeof value !== "boolean" &&
           typeof value !== "string" &&
       typeof value !== "number"){
        return undefined;
        }else{
            return value;
        }
}
*/
var tplSaveClick = function (pButton) {
    try {
        //var lStr = pButton.attr('id');
        var lItemID = pButton.attr('data-itemid');
        var lTable = pButton.attr('data-refer');
        var lClass = $('#TT' + lTable + lItemID).val();
        bootbox.prompt(translator.get("Template Name"), function (result) {
            if (result) {
                $('.sm-saving').show();
                populateStyleValues(lClass, lTable);
                //var styletext = '{';
                //for(var name in stylevalues) {
                //	if(typeof stylevalues[name] === "boolean" ||
                //	   typeof stylevalues[name] === "string" ||
                //	   typeof stylevalues[name] === "number"){
                //		styletext += name + ':"' + stylevalues[name] + '",';
                //	}
                //}
                //styletext = styletext.slice(0, -1) + '}';
                var styletext = JSON.stringify(stylevalues /*,replacer*/);
                try {
                    styletext.replace(/\u2028/g, '\\u2028').replace(/\u2029/g, '\\u2029');
                } catch (e) {
                    console.error(e);
                }
                var lRes = {
                    rName: result,
                    rTable: lTable,
                    rClass: lClass,
                    rCollection: fIDCollectionOrder,
                    rValues: styletext,
                    rIDRetailer: fIDRetailerOrder,
                    rIDWholesaler: fIDWholesalerOrder
                };
                if (fDebug) {
                    console.debug('Class:' + lClass);
                    console.debug(styletext);
                    console.debug(lRes);
                    console.debug(stylevalues);
                }
                var lArr = [];
                lArr.push(lRes);
                var lData = JSON.stringify(lArr);
                try {
                    lData.replace(/\u2028/g, '\\u2028').replace(/\u2029/g, '\\u2029');
                } catch (e) {
                    console.error(e);
                }
                $.ajax({
                    type: 'post',
                    dataType: 'json',
                    url: '/entry2/savestyle.fsscript?' + lData,
                    data: lData,
                    success: function (r) {
                        if (fDebug) {
                            console.debug(r);
                        }
                        if (r.error !== true && r.result === true) {
                            showMessage(translator.get('Style Saved'));
                            $('.TemplateTypes').change();
                            $('.sm-saving').hide();
                            try {
                                if (fActiveGrid) {
                                    fActiveGrid.bootgrid("reload");
                                }
                            } catch (e) {
                                console.error(e);
                            }
                        } else {
                            $('.sm-saving').hide();
                            showError(translator.get('Error Saving Style') + ':' + r.error0);
                        }
                    },
                    error: function (e) {
                        $('.sm-saving').hide();
                        showError(translator.get('Error Saving Style'));
                        console.log(e);
                    }
                });
            }
        });
    } catch (e) {
        console.error(e);
    }
};

var tplDeleteClick = function (pButton) {
    try {
        //var lButton = pButton;
        //var lCustomerGUID = $('#CustomerGUID').val();
        //var lStr = pButton.attr('id');
        var lItemID = pButton.attr('data-itemid');
        var lTable = pButton.attr('data-refer');
        var lTemplate = $('#AT' + lTable + lItemID).val();
        //var lTableSrc = getToken(lTemplate, '|', 0);
        //var lTableDst = getToken(lTemplate, '|', 1);
        //var IGUID = getToken(lTemplate, '|', 2);
        //var lClass = parseInt($('#TT' + lTable).val());
        //var lParams = [];
        //var lError = false;
        //var lErrorStr = '';

        var lTemplateName = $("#AT" + lTable + lItemID + " option:selected").text();

        bootbox.confirm({
            size: "small",
            message: translator.get("Delete Template") + " " + lTemplateName,
            callback: function (result) {
                if (result) {
                    $('.sm-saving').show();
                    $.ajax({
                        type: 'post',
                        dataType: 'json',
                        url: '/entry2/deletestyle.fsscript?template=' + lTemplate,
                        data: {
                            template: lTemplate
                        },
                        success: function (r) {
                            if (fDebug) {
                                console.debug(r);
                            }
                            if (r.result === true) {
                                showMessage(translator.get('Template Deleted'));
                                $('.TemplateTypes').change();
                                $('.sm-saving').hide();
                            } else {
                                $('.sm-saving').hide();
                                showError(translator.get('Error Deleting Template'));
                            }
                        },
                        error: function (e) {
                            $('.sm-saving').hide();
                            showError(translator.get('Error Deleting Template'));
                            console.log(e);
                        }
                    });
                }
            }
        });
    } catch (e) {
        console.error(e);
    }
};

/*
Sleeve InseamS = X
Chest=Y
Crown to Cuff =(X + 7.8) + ((Y-34)*0.08)
*/

function recalcCTOC() {
    var INSEAM = parseFloat($('#INSEAM').val());
    var CHEST = parseFloat($('#CHEST').val());
    var CTOC = (INSEAM + 7.8) + ((CHEST - 34) * 0.08);
    $('#CTOC').fuzzySet(CTOC, 3);
    $('#CTOC').change();
    if (fDebug) {
        console.debug(CTOC);
    }
}

function doJWRecalc($) {

    var HWGARMENT = $('#HWGARMENT');
    var JHW = $('#JHW').val();
    //var JWM = $('#JWM');
    var lCalc = 0;

    if ((HWGARMENT.val() === 'HWGARMENT_1') || // Jacket Regular
        (HWGARMENT.val() === 'HWGARMENT_2') || // Jacket Fitted
        (HWGARMENT.val() === 'HWGARMENT_10')) { // Evening Tail coat
        lCalc = (JHW * 2) - 5;
    } else if ((HWGARMENT.val() === 'HWGARMENT_4') || // Jacket Comfortable
        (HWGARMENT.val() === 'HWGARMENT_7') || // Coat Fitted
        (HWGARMENT.val() === 'HWGARMENT_8')) { // Coat Covert
        lCalc = (JHW * 2) - 6;
    } else if (HWGARMENT.val() === 'HWGARMENT_3') { // Jacket Slim
        lCalc = (JHW * 2) - 4;
    } else if (HWGARMENT.val() === 'HWGARMENT_9') { // Morning coat
        lCalc = (JHW * 2) - 7.75;
    } else if (HWGARMENT.val() === 'HWGARMENT_5') { // Coat Classic
        lCalc = (JHW * 2) - 7;
    } else {
        lCalc = 0;
    }
    lCalc = toFixed(lCalc, 3);
    $("#JWM").selectOptions(lCalc, true);
    //console.log(lCalc);
}

function doRecalcJWTYPE($) {
    var lJWTYPE = $('#JWTYPE').val();
    var lJHW = parseInt($('#JHW').val());
    var lJWM = parseInt($('#JWM').val());
    var lVal = 0.0;

    if (fDebug) {
        console.debug('doRecalcJWTYPE Start>>>');
    }

    $('#JWM').removeAttr('disabled');
    $('#JHW').removeAttr('disabled');
    if ((lJWTYPE === 'JWTYPE_1') && (fJWTYPE_OLD === 'JWTYPE_3')) {
        if (fDebug) {
            console.debug('Changed from JWTYPE_3 to JWTYPE_1 Half to Full');
        }
        lVal = (2 * (lJHW - 2.75));
        $('#JWM').fuzzySet(lVal);
        fJWDIRECTION = 1;
        fJWTYPE_OLD = lJWTYPE;
        $('#JWM').attr('disabled', 'disabled');
    } else if ((lJWTYPE === 'JWTYPE_1') && (fJWTYPE_OLD === 'JWTYPE_4')) {
        if (fDebug) {
            console.debug('Changed from JWTYPE_4 to JWTYPE_1 Half to Full');
        }
        lVal = (2 * (lJHW - 4.8));
        $('#JWM').fuzzySet(lVal);
        fJWDIRECTION = 2;
        fJWTYPE_OLD = lJWTYPE;
        $('#JWM').attr('disabled', 'disabled');
    } else if ((lJWTYPE === 'JWTYPE_3') && (fJWTYPE_OLD === 'JWTYPE_1')) {
        if (fDebug) {
            console.debug('Changed from JWTYPE_1 to JWTYPE_3 Full to Half');
        }
        lVal = ((0.5 * lJWM) + 2.75);
        $('#JHW').fuzzySet(lVal);
        fJWDIRECTION = 3;
        fJWTYPE_OLD = lJWTYPE;
        $('#JHW').attr('disabled', 'disabled');
    } else if ((lJWTYPE === 'JWTYPE_3') && (fJWTYPE_OLD === 'JWTYPE_2')) {
        if (fDebug) {
            console.debug('Changed from JWTYPE_2 to JWTYPE_3 Full to Half');
        }
        lVal = (lJWM - (0.5 * (parseFloat($('#JWTYPE').SizeMatrix('JKT', 'WAISTN', 'over')))) +
            2.75);
        $('#JHW').fuzzySet(lVal);
        fJWDIRECTION = 4;
        fJWTYPE_OLD = lJWTYPE;
        $('#JHW').attr('disabled', 'disabled');
    } else if ((lJWTYPE === 'JWTYPE_3') && (fJWTYPE_OLD === 'JWTYPE_4')) {
        if (fDebug) {
            console.debug('Changed from JWTYPE_4 to JWTYPE_3 Full to Half');
        }
        lVal = (lJWM + 2.75 - 4.8);
        $('#JHW').fuzzySet(lVal);
        fJWDIRECTION = 5;
        fJWTYPE_OLD = lJWTYPE;
        $('#JHW').attr('disabled', 'disabled');
    } else if ((lJWTYPE === 'JWTYPE_4') && (fJWTYPE_OLD === 'JWTYPE_1')) {
        if (fDebug) {
            console.debug('Changed from JWTYPE_1 to JWTYPE_4 Full to Half');
        }
        lVal = ((0.5 * lJWM) + 4.8);
        $('#JHW').fuzzySet(lVal);
        fJWDIRECTION = 6;
        fJWTYPE_OLD = lJWTYPE;
        $('#JHW').attr('disabled', 'disabled');
    } else if ((lJWTYPE === 'JWTYPE_4') && (fJWTYPE_OLD === 'JWTYPE_2')) {
        if (fDebug) {
            console.debug('Changed from JWTYPE_2 to JWTYPE_4 Full to Half');
        }
        lVal = (lJWM - (0.5 * parseFloat($('#JWTYPE').SizeMatrix('JKT', 'WAISTN', 'over'))) + 4.8);
        $('#JHW').fuzzySet(lVal);
        fJWDIRECTION = 7;
        fJWTYPE_OLD = lJWTYPE;
        $('#JHW').attr('disabled', 'disabled');
    } else if ((lJWTYPE === 'JWTYPE_4') && (fJWTYPE_OLD === 'JWTYPE_3')) {
        if (fDebug) {
            console.debug('Changed from JWTYPE_3 to JWTYPE_4 Full to Half');
        }
        lVal = (lJWM - 2.75 + 4.8);
        $('#JHW').fuzzySet(lVal);
        fJWDIRECTION = 8;
        fJWTYPE_OLD = lJWTYPE;
        $('#JHW').attr('disabled', 'disabled');
    } else {
        if (fDebug) {
            console.debug('No Change Using:' + fJWDIRECTION);
        }
        switch (fJWDIRECTION) {
            case 1:
                lVal = (2 * (lJHW - 2.75));
                $('#JWM').fuzzySet(lVal);
                $('#JWM').attr('disabled', 'disabled');
                break;
            case 2:
                lVal = (2 * (lJHW - 4.8));
                $('#JWM').fuzzySet(lVal);
                $('#JWM').attr('disabled', 'disabled');
                break;
            case 3:
                lVal = ((0.5 * lJWM) + 2.75);
                $('#JHW').fuzzySet(lVal);
                $('#JHW').attr('disabled', 'disabled');
                break;
            case 4:
                lVal = (lJWM - (0.5 * (parseFloat($('#JWTYPE').SizeMatrix('JKT', 'WAISTN', 'over')))) + 2.75);
                $('#JHW').fuzzySet(lVal);
                $('#JHW').attr('disabled', 'disabled');
                break;
            case 5:
                lVal = (lJWM + 2.75 - 4.8);
                $('#JHW').fuzzySet(lVal);
                $('#JHW').attr('disabled', 'disabled');
                break;
            case 6:
                lVal = ((0.5 * lJWM) + 4.8);
                $('#JHW').fuzzySet(lVal);
                $('#JHW').attr('disabled', 'disabled');
                break;
            case 7:
                lVal = (lJWM - (0.5 * parseFloat($('#JWTYPE').SizeMatrix('JKT', 'WAISTN', 'over'))) + 4.8);
                $('#JHW').fuzzySet(lVal);
                $('#JHW').attr('disabled', 'disabled');
                break;
            case 8:
                lVal = (lJWM - 2.75 + 4.8);
                $('#JHW').fuzzySet(lVal);
                $('#JHW').attr('disabled', 'disabled');
                break;
        }
    }

    if (fDebug) {
        console.debug('fJWTYPE_OLD:' + fJWTYPE_OLD);
        console.debug('lJWTYPE:' + lJWTYPE);
        console.debug('JHW:' + $('#JHW').val());
        console.debug('JWM:' + $('#JWM').val());
        console.debug('Result:' + lVal);
    }
}


function doRecalc($, T_SEAT, T_UMES, T_WAIST, T_THIGH, T_OL, T_SD_PLEATS, T_F_LF) {
    var lParam =
        'T_SEAT=' + T_SEAT + '&' +
        'T_UMES=' + T_UMES + '&' +
        'T_WAIST=' + T_WAIST + '&' +
        'T_THIGH=' + T_THIGH + '&' +
        'T_OL=' + T_OL + '&' +
        'T_SD_PLEATS=' + T_SD_PLEATS + '&' +
        'T_SD_PD=' + false + '&' +
        'T_F_LF=' + T_F_LF;

    var lUrl = '/scripts-pas/CalcIL.fsscript?' + lParam;

    //	console.log('doRecalc:'+lParam);

    $.ajax({
        type: 'post',
        url: lUrl,
        dataType: 'json',
        data: {
            "T_SEAT": T_SEAT,
            "T_UMES": T_UMES,
            "T_WAIST": T_WAIST,
            "T_THIGH": T_THIGH,
            "T_OL": T_OL,
            "T_SD_PLEATS": T_SD_PLEATS,
            "T_SD_PD": false,
            "T_F_LF": T_F_LF
        },
        success: function (r) {
            if (fDebug) {
                console.debug(r);
            }
            $("#T_IL").selectOptions(r.T_IL, true);
        },
        error: function (request, status, error) {
            console.error('Ajax Error:' + error);
        }
    });
}

var recalcJWTYPEChange = function () {
    doRecalcJWTYPE($);
};

var recalcILChange = function () {
    //console.log('recalc change');
    doRecalc($,
        $("#T_SEAT").val(),
        $("#T_UMES").val(),
        $("#T_WAIST").val(),
        $("#T_THIGH").val(),
        $("#T_OL").val(),
        $("#T_SD_PLEATS").val(),
        $("#T_F_LF").val());
};

var printNowClick = function () {
    window.print();
};

function hasClothCommentsWarning(pButton) {
    var lHasCustomCloths = false;
    $("#editorderform *").filter(':input').each(function () {
        var realName = pButton.attr('id');
        if (typeof realName !== 'undefined') {
            if (typeof pButton.attr('data-id') !== 'undefined') {
                if (pButton.attr('data-cguid') === cCustomClothGUID) {
                    lHasCustomCloths = true;
                }
            }
        }
    });
    return lHasCustomCloths;
}

var loadFromTrouser = function (pSource, pPfx) {
    $('#' + pSource + ' *').filter(':input').each(function () {
        var realName = $(this).attr('id');
        if (typeof realName !== 'undefined') {
            if (typeof $(this).attr('data-id') !== 'undefined') {
                if (fDebug) {
                    console.debug('#' + pPfx + $(this).attr('data-id'));
                }
                var lObj = $('#' + pPfx + $(this).attr('data-id'));
                if ($(this).val() !== lObj.val()) {
                    lObj.trigger('visiblecheck');
                    lObj.val($(this).val());
                    lObj.updateField();
                    if ($(this).attr('data-cguid') !== '') {
                        lObj.attr('data-cguid', $(this).attr('data-cguid'));
                    }
                    lObj.trigger('verifyme');
                }
            }
        }
    });
};

var copyCustomCloths = function (pSource) {
    var sourceName = pSource.attr('id');
    var canCopy = false;
    if (sourceName === 'J2_COMMENTCLOTH') {
        canCopy = true;
    }
    if ((sourceName === 'LS2_COMMENTCLOTH') && !fJKTII) {
        canCopy = true;
    }
    if (sourceName === 'G2_COMMENTCLOTH') {
        canCopy = true;
    }
    if ((sourceName === 'P2_COMMENTCLOTH') && !fJKTII) {
        canCopy = true;
    }
    if ((sourceName === 'V2_COMMENTCLOTH') && !fJKTII) {
        canCopy = true;
    }
    if ((sourceName === 'T2_COMMENTCLOTH') && !fJKTII && !fVII) {
        canCopy = true;
    }
    if ((sourceName === 'DR2_COMMENTCLOTH') && !fJKTII) {
        canCopy = true;
    }
    if (canCopy) {
        $("#editorderform *").filter(':input').each(function () {
            var realName = $(this).attr('id');
            if (typeof realName !== 'undefined') {
                if ((sourceName !== realName) && (realName.indexOf('COMMENTCLOTH') >= 0)) {
                    $(this).val(pSource.val());
                    $(this).text(pSource.val());
                }
            }
        });
    }
};

Date.prototype.addWorkDays = function (days) {
    if (isNaN(days)) {
        console.log("Value provided for \"days\" was not a number");
        return;
    }

    // Get the day of the week as a number (0 = Sunday, 1 = Monday, .... 6 = Saturday)
    var dow = this.getDay();
    var daysToAdd = parseInt(days);
    // If the current day is Sunday add one day
    if (dow === 0) {
        daysToAdd++;
    }
    // If the start date plus the additional days falls on or after the closest Saturday calculate weekends
    if (dow + daysToAdd >= 6) {
        //Subtract days in current working week from work days
        var remainingWorkDays = daysToAdd - (5 - dow);
        //Add current working week's weekend
        daysToAdd += 2;
        if (remainingWorkDays > 5) {
            //Add two days for each working week by calculating how many weeks are included
            daysToAdd += 2 * Math.floor(remainingWorkDays / 5);
            //Exclude final weekend if the remainingWorkDays resolves to an exact number of weeks
            if (remainingWorkDays % 5 === 0) {
                daysToAdd -= 2;
            }
        }
    }
    this.setDate(this.getDate() + daysToAdd);
};

var relinkEvents = function () {

    if (fDebug) {
        console.log('Relinking Events');
    }

    $('.dropdown-toggle').dropdown();

    /* Change Events */

    $('.form-control.verinput').on('change', function () {
        var that = this;
        $(that).removeUnicode();
        verinputChange($(this), function () {
            $(that).updateField();
        });
    });

    $('.bscheck').on('change', function () {
        $(this).updateField();
    });

    $('.form-control.TemplateTypes').on('change', function () {
        templateTypesChange($(this));
    });

    $('.form-control.AvailableTemplates').on('change', function () {
        availableTemplatesChange($(this));
    });

    $('.recalcJWTYPE').on('change', function () {
        recalcJWTYPEChange($(this));
    });

    $('.recalcIL').on('change', function () {
        recalcILChange($(this));
    });

    /* Click Events*/

    $('.clothbutton').unbind('click').on('click', function () {
        selectCloth($(this));
    });

    $('.detailsbutton').unbind('click').on('click', function () {
        detailsButtonClick($(this));
    });

    $('.btn-sm').unbind('click').on('click', function () {
        $('.verinput').change();
    });

    $('.recheck').unbind('click').on('click', function () {
        $('.verinput').change();
    });

    $('.orderlist').unbind('click').on('click', function () {
        orderListClick($(this));
    });

    $('.printlong').unbind('click').on('click', function () {
        checkPageChange('printorderlong', $('#RGUID').val(), previousPage);
    });

    $('.printshort').unbind('click').on('click', function () {
        checkPageChange('printorder', $('#RGUID').val(), previousPage);
    });

    $('.submitorder').unbind('click').on('click', function () {
        submitOrderClick($(this));
    });

    $('.submitsameLevel').unbind('click').on('click', function () {
        submitSameLevelClick($(this));
    });

    $('.savedraft').unbind('click').on('click', function () {
        saveDraftClick($(this));
    });

    $('.tplLoad').unbind('click').on('click', function () {
        tplLoadClick($(this));
    });

    $('.tplSave').unbind('click').on('click', function () {
        tplSaveClick($(this));
    });

    $('.tplDelete').unbind('click').on('click', function () {
        tplDeleteClick($(this));
    });

    $('.printnow').unbind('click').on('click', function () {
        printNowClick($(this));
    });

    $('.cpyTrs').unbind('click').on('click', function () {
        loadFromTrouser($(this).attr('data-firsttrs'), $(this).attr('data-pfx') + '_');
    });

    $('.preview3dorder').unbind('click').on('click', function () {
        previewOrder3D();
    });

    $('.mobilenav').unbind('click').on('click', mobileNavClick);

    // Shouldn't have to do the below, but randomly stopped working 24/01/23

    $('.nav-tabs a').unbind('click').on('click', function (e) {
        e.preventDefault();
        $(this).tab('show');
    });

    $('.collapsed').unbind('click').on('click', function (e) {
        e.preventDefault();
        var id = $(this).attr('href');
        $(id).collapse('toggle');
    });

    $('.panel-title a').unbind('click').on('click', function (e) {
        e.preventDefault();
        var id = $(this).attr('href');
        $(id).collapse('toggle');
    });

    $(document).on('click.bs.toggle', 'div[data-toggle^=toggle]', function (e) {
        e.preventDefault();
        var $checkbox = $(this).find('input[type=checkbox]');
        $checkbox.bootstrapToggle('toggle');
    });

    ///

    ErrorObjectList.div = $('#ErrorListBox');
    WarnObjectList.div = $('#WarnListBox');

    fJWTYPE_OLD = $('#JWTYPE').val();
    fJWDIRECTION = 1;
};

var ErrorObjectList = new ErrorList($('#ErrorListBox'));
var WarnObjectList = new WarnList($('#WarnListBox'));